// React
import React, { useEffect, useState } from "react";

// CSS styling
import "../styles/admin.css";

// Loader
import Loader from "./Loader";

// Font Awesome React
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

// Icons
import uploadImageBtn from "../images/icons/admin/uploadImageBtn.png";
import iconChangeImage from "../images/icons/admin/changeImageIcon.png";
import deleteIcon from "../images/icons/DeleteIcon.png";

// React lottie
import Lottie from "react-lotties";

// Components
import EditorText from "./EditorText";
import { deleteNewsContentImage } from "../api/deleteNewsContentImage";

const PostForm = (props) => {
  const {
    navigate,
    categories,
    isLoading,
    setLoading,
    changeStatus,
    statusName,
    handleStatusName,
    handleStatusImage,
    EllipseDraft,
    EllipseHidden,
    EllipsePublished,
    editId,
    lottieOptions,
    handleSave,
    setShowAlertWorkshop,
    setWorkshopAlertMsg,
    editForm,
    thumbnailImageFailedToUploadMsg,
    // contentImageFailedToUploadMsg,
  } = props;

  const [isOpenCategoryDropdown, setIsOpenCategoryDropdown] = useState(false);
  const [isOpenStatusDropdown, setIsOpenStatusDropdown] = useState(false);

  const [categoryVal, setCategoryVal] = useState({
    label: "Article",
    value: null,
  });
  const [title, setTitle] = useState("");
  const [writersName, setWritersName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [content, setContent] = useState("");
  const [thumbnailImageToSave, setThumbnailImageToSave] = useState(null);
  const [thumbnailImageToView, setThumbnailImageToView] = useState(null);
  // const [contentImageToSave, setContentImageToSave] = useState(null);
  const [contentImageToView, setContentImageToView] = useState(null);

  const [isEmptyTitle, setIsEmptyTitle] = useState(false);
  const [isEmptyWritersName, setIsEmptyWritersName] = useState(false);
  const [isEmptyShortDescription, setIsEmptyShortDescription] = useState(false);
  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const [isEmptyCategoryVal, setIsEmptyCategoryVal] = useState(false);
  const [isEmptyThumbnailImage, setIsEmptyThumbnailImage] = useState(false);
  const [thumbnailImageSizeTooBigMsg, setThumbnailImageSizeTooBigMsg] =
    useState("");
  // const [isEmptyContentImage, setIsEmptyContentImage] = useState(false);
  // const [contentImageSizeTooBigMsg, setContentImageSizeTooBigMsg] =
  //   useState("");

  useEffect(() => {
    if (!editId && categories.length > 0) {
      setCategoryVal({
        label: categories[0].name,
        value: categories[0].id,
      });
    }
  }, [editId, categories]);

  useEffect(() => {
    if (editId && editForm) {
      setTitle(editForm.title);
      setWritersName(editForm.written_by);
      setShortDescription(editForm.description);
      setContent(editForm.htmlContent);
      setThumbnailImageToView({ url: editForm.picture });
      setContentImageToView({ url: editForm.content_image });
      setCategoryVal({
        label: editForm.Category.name,
        value: editForm.Category.id,
      });
    }
  }, [editId, editForm]);

  const onImageChange = (files, key) => {
    if (files && files[0]) {
      const { size } = files[0] || {};
      if (key === "thumbnailImage") {
        let maxFileSize = 50 * 1024 * 1024; // make 50Mb max file size
        if (size >= maxFileSize) {
          setThumbnailImageSizeTooBigMsg("File too large (Max: 50Mb)");
        } else {
          setThumbnailImageSizeTooBigMsg("");
        }
        setThumbnailImageToSave(files[0]);
        setThumbnailImageToView({
          fileName: files[0].name,
          url: URL.createObjectURL(files[0]),
        });
      }
      //  else {
      //   if (size >= maxFileSize) {
      //     setContentImageSizeTooBigMsg("File too large (Max: 1mb)");
      //   } else {
      //     setContentImageSizeTooBigMsg("");
      //   }
      //   setContentImageToSave(files[0]);
      //   setContentImageToView({
      //     fileName: files[0].name,
      //     url: URL.createObjectURL(files[0]),
      //   });
      // }
    }
  };

  const onInputChange = (value, setter) => {
    setter(value);
  };

  // Handle category and status change button on click
  const handleButton = (name) => {
    if (name === "category") {
      if (!isOpenCategoryDropdown) {
        setIsOpenCategoryDropdown(true);
        setIsOpenStatusDropdown(false);
      } else {
        setIsOpenCategoryDropdown(false);
      }
    } else {
      if (!isOpenStatusDropdown) {
        setIsOpenStatusDropdown(true);
        setIsOpenCategoryDropdown(false);
      } else {
        setIsOpenStatusDropdown(false);
      }
    }
  };

  const onDropdownChange = (val) => {
    setCategoryVal({ label: val.name, value: val.id });
  };

  const checkAllInputs = (e) => {
    e.preventDefault();
    setLoading(true);

    let emptyTitle = false;
    let emptyWritersName = false;
    let emptyShortDescription = false;
    let emptyContent = false;
    let emptyThumbnailImage = false;
    // let emptyContentImage = false;
    let emptyCategoryVal = false;

    if (!title) {
      emptyTitle = true;
    }
    if (!writersName) {
      emptyWritersName = true;
    }
    if (!shortDescription) {
      emptyShortDescription = true;
    }
    if (!content) {
      emptyContent = true;
    }
    if (!editId) {
      if (!thumbnailImageToSave) {
        emptyThumbnailImage = true;
      }
      // if (!contentImageToSave) {
      //   emptyContentImage = true;
      // }
    }

    if (!categoryVal.label || !categoryVal.value) {
      emptyCategoryVal = true;
    }

    setIsEmptyTitle(emptyTitle);
    setIsEmptyWritersName(emptyWritersName);
    setIsEmptyShortDescription(emptyShortDescription);
    setIsEmptyContent(emptyContent);
    setIsEmptyThumbnailImage(emptyThumbnailImage);
    // setIsEmptyContentImage(emptyContentImage);
    setIsEmptyCategoryVal(emptyCategoryVal);

    if (
      emptyTitle !== true &&
      emptyWritersName !== true &&
      emptyShortDescription !== true &&
      emptyContent !== true &&
      emptyCategoryVal !== true
    ) {
      if (editId) {
        handleSave({
          title,
          writersName,
          shortDescription,
          thumbnailImageToSave,
          // contentImageToSave,
          categoryVal,
          statusName,
          content,
        });
      } else {
        if (
          emptyThumbnailImage !== true
          // && emptyContentImage !== true
        ) {
          handleSave({
            title,
            writersName,
            shortDescription,
            thumbnailImageToSave,
            // contentImageToSave,
            categoryVal,
            statusName,
            content,
          });
        } else {
          setLoading(false);
          setShowAlertWorkshop(true);
          setWorkshopAlertMsg("Please check the error fields");
        }
      }
    } else {
      setLoading(false);
      setShowAlertWorkshop(true);
      setWorkshopAlertMsg("Please check the error fields");
    }
  };

  const handleOnDeleteContentImage = async (newsId) => {
    try {
      const result = await deleteNewsContentImage(newsId);
      if (result && result.data) {
        const { data: { content_image } = {} } = result || {};
        setContentImageToView(content_image);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  return (
    <div className="adminPostList">
      {isLoading ? null : (
        <div className="adminPostListTitleContainer">
          <div className="adminPostListTitle">
            <p>{editId ? "Edit Post" : "Add Post"}</p>
          </div>
          <div className="adminPostListFunction">
            <div className="adminPostListEachFunction">
              <p>Category</p>
              <div
                className={`adminCategoryDropdown ${
                  isEmptyCategoryVal && !categoryVal.value
                    ? "adminDropdownError"
                    : "adminDropdown"
                }`}
                onClick={() => handleButton("category")}
              >
                <div className="adminDropdownButton">
                  <button>
                    <span>{categoryVal.label}</span>
                  </button>
                  <span className="adminDropdownIcon">
                    <FontAwesomeIcon
                      style={{
                        float: "right",
                        marginLeft: "-2em",
                        color: "black",
                      }}
                      icon={faAngleDown}
                    />
                  </span>
                </div>
                {isOpenCategoryDropdown && (
                  <div className="ArticleButton categoryButton">
                    <div className="ArticleButtonContainer">
                      <div className="ArticleList">
                        {categories.map((category) => (
                          <div
                            className="ArticleChildrenList"
                            key={category.id}
                            onClick={() => onDropdownChange(category)}
                          >
                            <p>{category.name}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="adminPostListEachFunction">
              <p>Status</p>
              <div
                className="adminStatusDropdown adminDropdown"
                onClick={() => handleButton("status")}
              >
                <div className="adminDropdownButton">
                  <button>
                    <span>
                      <img src={handleStatusImage(statusName)} alt="Hidden" />
                    </span>
                    <span>{handleStatusName(statusName)}</span>
                  </button>
                  <span className="adminDropdownIcon">
                    <FontAwesomeIcon
                      style={{
                        float: "right",
                        marginLeft: "-2em",
                        color: "black",
                      }}
                      icon={faAngleDown}
                    />
                  </span>
                </div>
                {isOpenStatusDropdown && (
                  <div className="ArticleButton statusButton">
                    <div className="ArticleButtonContainer">
                      <div className="ArticleList">
                        <div
                          className="ArticleChildrenList"
                          onClick={() => changeStatus("Hidden")}
                        >
                          <p>
                            <span>
                              <img src={EllipseHidden} alt="Hidden" />
                            </span>
                            Hidden
                          </p>
                        </div>
                        <div
                          className="ArticleChildrenList"
                          onClick={() => changeStatus("Draft, Not published")}
                        >
                          <p>
                            <span>
                              <img src={EllipseDraft} alt="Draft" />
                            </span>
                            Draft
                          </p>
                        </div>
                        <div
                          className="ArticleChildrenList"
                          onClick={() => changeStatus("Published")}
                        >
                          <p>
                            <span>
                              <img src={EllipsePublished} alt="Published" />
                            </span>
                            Published
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <div
          style={{
            width: "75%",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "5em",
          }}
        >
          <Lottie options={lottieOptions} height={200} width={400} />
          <h4
            style={{
              color: "#0D4C86",
            }}
          >
            LOADING
          </h4>
          <p
            style={{
              color: "#0D4C86",
            }}
          >
            Processing your request, please wait...
          </p>
        </div>
      ) : (
        <div className="adminPostAdd">
          <form>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Title</div>
              </div>
              <textarea
                required
                name="title"
                type="text"
                className="xSmallInputBox"
                placeholder="Please fill in title"
                value={title}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setTitle)
                }
              ></textarea>
              {isEmptyTitle && !title ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Writer's name</div>
              </div>
              <textarea
                required
                name="writersName"
                type="text"
                className="xSmallInputBox"
                placeholder="Please fill in Writer's name"
                value={writersName}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setWritersName)
                }
              ></textarea>
              {isEmptyWritersName && !writersName ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Short description</div>
              </div>
              <textarea
                required
                name="shortDescription"
                type="text"
                className="xSmallInputBox"
                placeholder="Please fill in Short description"
                value={shortDescription}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setShortDescription)
                }
              ></textarea>
              {isEmptyShortDescription && !shortDescription ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
              {shortDescription.length > 50 ? (
                <div className="errorMessage">
                  <p>Please limit data to maximum of 50 characters.</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className="eachLabel">Thumbnail Image</label>
                <span style={{ marginTop: "-0.5em" }}>
                  This image will appear as the thumbnail of the post
                </span>
              </div>
              <div
                className={
                  !thumbnailImageToView
                    ? "inputImageContainer"
                    : "inputImageContainerNoPointer"
                }
                onClick={() =>
                  !thumbnailImageToView
                    ? document
                        .querySelector(`.input-field-thumbnail-image`)
                        .click()
                    : null
                }
                style={{ marginTop: "0.5em" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  className={`input-field-thumbnail-image`}
                  hidden
                  onChange={({ target: { files } }) =>
                    onImageChange(files, "thumbnailImage")
                  }
                />
                <div className="imageToViewContainer">
                  <img
                    src={
                      thumbnailImageToView && thumbnailImageToView.url
                        ? thumbnailImageToView.url
                        : uploadImageBtn
                    }
                    style={
                      thumbnailImageToView && thumbnailImageToView.url
                        ? { maxWidth: 250, maxHeight: 175 }
                        : { maxWidth: 35, maxHeight: 35 }
                    }
                    alt="thumbnailImage"
                  ></img>
                  {!thumbnailImageFailedToUploadMsg &&
                  thumbnailImageToView &&
                  thumbnailImageToView.fileName ? (
                    <div className="imageFileNameFontStyle">
                      {thumbnailImageToView.fileName}
                    </div>
                  ) : null}
                  {thumbnailImageFailedToUploadMsg && (
                    <div className="errorFailedToUpload">
                      <p>{thumbnailImageFailedToUploadMsg}</p>
                    </div>
                  )}
                </div>
                {thumbnailImageToView && (
                  <div
                    className="btnChangeImage"
                    onClick={() =>
                      document
                        .querySelector(`.input-field-thumbnail-image`)
                        .click()
                    }
                  >
                    <img
                      src={iconChangeImage}
                      alt="iconChangeImage"
                      width={17}
                      height={17}
                    ></img>
                    <div className="changeImageLabel">Change image</div>
                  </div>
                )}
                {thumbnailImageToView ? null : (
                  <div className="labelImage">
                    {"Drag & drop your file(s) here"}
                  </div>
                )}
                {thumbnailImageToView ? null : (
                  <div className="placeholderImage">
                    {"Recommended size is 300 x 300 px"}
                  </div>
                )}
              </div>
              {isEmptyThumbnailImage && !thumbnailImageToSave ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : thumbnailImageSizeTooBigMsg ? (
                <div className="errorMessage">
                  <p>{thumbnailImageSizeTooBigMsg}</p>
                </div>
              ) : null}
            </div>
            {contentImageToView && contentImageToView.url ? (
              <div className="adminPostInput">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className="eachLabel">Content Image</label>
                  <span style={{ marginTop: "-0.5em" }}>
                    This image will appear beside of the post content
                  </span>
                </div>
                <div
                  className={
                    // !contentImageToView
                    //   ? "inputImageContainer"
                    //   :
                    "inputImageContainerNoPointer"
                  }
                  // onClick={() =>
                  //   !contentImageToView
                  //     ? document
                  //         .querySelector(`.input-field-content-image`)
                  //         .click()
                  //     : null
                  // }
                  style={{ marginTop: "0.5em" }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    className={`input-field-content-image`}
                    hidden
                    onChange={({ target: { files } }) =>
                      onImageChange(files, "contentImage")
                    }
                  />
                  <div className="imageToViewContainer">
                    <img
                      src={
                        // contentImageToView &&
                        contentImageToView.url
                        // ? contentImageToView.url
                        // : uploadImageBtn
                      }
                      style={
                        // contentImageToView && contentImageToView.url
                        //   ?
                        { maxWidth: 250, maxHeight: 175 }
                        // : { maxWidth: 35, maxHeight: 35 }
                      }
                      alt="contentImage"
                    ></img>
                    {/* {!contentImageFailedToUploadMsg &&
                    contentImageToView &&
                    contentImageToView.fileName ? (
                      <div className="imageFileNameFontStyle">
                        {contentImageToView.fileName}
                      </div>
                    ) : null} */}
                    {/* {contentImageFailedToUploadMsg && (
                      <div className="errorFailedToUpload">
                        <p>{contentImageFailedToUploadMsg}</p>
                      </div>
                    )} */}
                  </div>
                  {contentImageToView && (
                    <div
                      className="btnChangeImage"
                      onClick={
                        () => handleOnDeleteContentImage(editId)
                        // document
                        //   .querySelector(`.input-field-content-image`)
                        //   .click()
                        // {
                        //   setContentImageToSave(null);
                        //   setContentImageToView(null);
                        // }
                      }
                    >
                      {/* <img
                        src={iconChangeImage}
                        alt="iconChangeImage"
                        width={17}
                        height={17}
                      ></img>
                      <div className="changeImageLabel">Change image</div> */}
                      <img
                        src={deleteIcon}
                        alt="iconDeleteImage"
                        width={17}
                        height={17}
                      ></img>
                      <div className="changeImageLabel">Remove image</div>
                    </div>
                  )}
                  {/* {contentImageToView ? null : (
                    <div className="labelImage">
                      {"Drag & drop your file(s) here"}
                    </div>
                  )}
                  {contentImageToView ? null : (
                    <div className="placeholderImage">
                      {"Recommended size is 300 x 300 px"}
                    </div>
                  )} */}
                </div>
                {/* {isEmptyContentImage && !contentImageToSave ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : contentImageSizeTooBigMsg ? (
                  <div className="errorMessage">
                    <p>{contentImageSizeTooBigMsg}</p>
                  </div>
                ) : null} */}
              </div>
            ) : null}
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Content</div>
              </div>
              <EditorText
                onChange={(value) => onInputChange(value, setContent)}
                preloadValue={content ? content : null}
              />
              {isEmptyContent && !content ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostButtonContainer">
              <div>
                <span
                  className="postCancelButton btn"
                  onClick={() => navigate("/admin/post")}
                >
                  Cancel
                </span>
              </div>
              <div>
                <button
                  className="postSaveButton btn"
                  onClick={(e) => checkAllInputs(e)}
                >
                  {isLoading ? (
                    <span>
                      <Loader
                        type="spin"
                        color="black"
                        height="25px"
                        width="25px"
                      />
                    </span>
                  ) : (
                    <span>Save</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PostForm;
