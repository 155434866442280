import React from "react";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import LearningVideosContent from "../../components/LearningVideosContent.js";

const LearningVideos = () => {
  return (
    <div>
      <Header />
      <div id="videos" style={{ paddingTop: "130px" }}>
        <LearningVideosContent />
      </div>
      <Footer />
    </div>
  );
};

export default LearningVideos;
