import { useEffect, useState } from "react";

// React DOM
import { useParams } from "react-router-dom";

// Api
import { downloadBrochure } from "../../api/downloadBrochure";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// React Form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// Images and Icons
import PopupMessage from "../../images/icons/PopupMessage1.png";

// CSS styling
import "../../styles/contact.css";

// react responsive
import { useMediaQuery } from "react-responsive";

// Helpers
import { openModal } from "../../helpers/openModal";
import { closeModal } from "../../helpers/closeModal";

// Sweetalert
import Swal from "sweetalert2";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const DownloadFileForm = () => {
  const { paramsKey } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const isMobileSize = useMediaQuery({ query: "(max-width: 640px)" });
  const isTabSize = useMediaQuery({
    query: "(min-width: 641px) and (max-width: 910px)",
  });

  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [statement, setStatement] = useState(false);
  const [email, setEmail] = useState(false);

  const handleStatement = () => setStatement(!statement);
  const handleEmail = () => setEmail(!email);

  const onSubmit = (values) => {
    if (statement && isCaptchaCorrect) {
      downloadBrochure({
        full_name: values.fullName,
        email: values.email,
        phone_number: values.phone,
        company_name: values.company,
        brochure_name:
          paramsKey === "dip-pm-esg"
            ? "brochure-dip-pm-esg"
            : // : paramsKey === "mm-usd"
            // ? "brochure-mm-usd"
            paramsKey === "registration-procedures-payment-and-class-schedule"
            ? "brochure-registration-procedures-payment-and-class-schedule"
            : "brochure-infographic-esg-sustainability",
      })
        .then((res) => {
          openModal();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(`Oops..`, `Something's wrong`, `error`);
        });
    } else {
      Swal.fire(
        `Oops..`,
        `Please verify our captcha and check our privacy statement`,
        `error`
      );
    }
  };

  const onChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="contact">
      <Header />
      <ContainerText
        props={
          <div
            className="contactContainer"
            style={{ marginTop: isMobileSize || isTabSize ? "5vh" : "-30vh" }}
          >
            <div className="contactFormContainer">
              <div className="contactBannerTitle header">
                <p>{`Download ${
                  paramsKey === "dip-pm-esg"
                    ? "Diploma PM for ESG"
                    : // : paramsKey === "mm-usd"
                    // ? "MM USD"
                    paramsKey ===
                      "registration-procedures-payment-and-class-schedule"
                    ? "Registration procedures, payment and class schedule"
                    : "Infographic about ESG & Sustainability"
                } brochure`}</p>
              </div>
              <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Full Name</label>
                  <input
                    name="fullName"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your name"
                    {...register("fullName", {
                      required: "Please input your full name",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "alphabet characters only",
                      },
                      minLength: {
                        value: 3,
                        message: "mininum 3 characters",
                      },
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="fullName"
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Company Name</label>
                  <input
                    name="company"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your company name"
                    {...register("company", {
                      required: "Please enter your company name",
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="company"
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Email Address</label>
                  <input
                    name="email"
                    type="email"
                    className="form-control text"
                    placeholder="Enter your email address"
                    {...register("email", {
                      required: "Please enter valid email address",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "email format invalid, please try again..",
                      },
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Phone Number</label>
                  <input
                    name="phone"
                    type="number"
                    className="form-control text"
                    placeholder="Enter your phone number"
                    {...register("phone", {
                      required: "Please enter your phone number",
                      pattern: {
                        value: /^(?=.*\d).{8,13}$/,
                        message:
                          "Must be minimum 8 characters and maximum 13 characters",
                      },
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="phone"
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
                <div
                  className="dipPmESGDescEachContainerTitleHr"
                  style={{ marginTop: "5vh" }}
                />
                <div className="contactFormCheckbox text">
                  <input
                    onChange={handleStatement}
                    checked={statement}
                    type="checkbox"
                  />
                  <span>
                    {" "}
                    I agree to the ISA's{" "}
                    <a
                      href="Policy/PrivacyStatement"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy statement
                    </a>
                  </span>
                  <br />
                  <input
                    onChange={handleEmail}
                    checked={email}
                    type="checkbox"
                  />
                  <span>
                    {" "}
                    I allow ISA to send me email communications, including
                    marketing promotions, insights and news updates
                  </span>
                </div>
                <ReCAPTCHA
                  sitekey="6LfXMYAeAAAAAAjkbjSL22SnQ5lqP29nDdPDuAO0"
                  onChange={onChangeCaptcha}
                  className="recaptchaStyle"
                />
                <div className="contactFormSubmit">
                  <button
                    type="submit"
                    className="btn btn-warning paragrafDesc"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        }
      />
      <div id="popupModal" className="overlay">
        <div className="popup">
          <img src={PopupMessage} alt="popupMessage"></img>
          <p className="headerDescription">An email sent to your inbox</p>
          <a className="popupClose" href="#contact" onClick={closeModal}>
            &times;
          </a>
          <div className="popupContent paragraf">
            Please check email sent to you and click download button to continue
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DownloadFileForm;
