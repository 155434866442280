import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import ContainerText from "../../components/Container";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

// React Bootstrap
import { Accordion } from "react-bootstrap";

// Images
import CompanyBanner from "../../images/background/company.png";
import ISAacademy1 from "../../images/icons/isaAcademy/ISA_Academy_1.png";
import ISAacademy2 from "../../images/icons/isaAcademy/ISA_Academy_2.png";
import ISAacademy4 from "../../images/icons/isaAcademy/ISA_Academy_4.png";
import ISAacademy5 from "../../images/icons/isaAcademy/ISA_Academy_5.png";
import ZoomIcon from "../../images/icons/zoom_icon_logo.png";
import ChatBubble from "../../images/icons/ChatBubble.png";
import DownloadBlue from "../../images/icons/DownloadBlue.png";
import SearchIcon from "../../images/icons/SearchIcon.png";
import FilterIcon from "../../images/icons/FilterIcon.png";
import SortbyIcon from "../../images/icons/SortbyIcon.png";
import ClickedFilterIcon from "../../images/icons/ClickedFilterIcon.png";
import ClickedSortbyIcon from "../../images/icons/ClickedSortbyIcon.png";
import Method from "../../images/icons/Method.png";
import Location from "../../images/icons/Location.png";
import DateIcon from "../../images/icons/Date.png";
import NotFound from "../../images/icons/NotFound.png";
import NoWorkshopAvailable from "../../images/icons/NoWorkshopAvailable.png";
import VocationalSectionLogo from "../../images/companyLogo/vocational-logo.png";
import workshop_testimonial_Gandung_Pratidhina from "../../images/internTestimonial/workshop_testimonial_Gandung_Pratidhina.jpeg";
import workshop_testimonial_Annette_Apriliana from "../../images/internTestimonial/workshop_testimonial_Annette_Apriliana.jpeg";
import ISA_academy_detail_background from "../../images/background/ISA_academy_detail_background.jpeg";
import workshop_testimonial_Achmad_Siswandy_Asdam from "../../images/internTestimonial/workshop_testimonial_Achmad_Siswandy_Asdam.jpeg";
import ISAmobile from "../../images/icons/ISA.png";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// HTML react parser
import parse from "html-react-parser";

// CSS
import "../../styles/academy.css";
import "../../styles/about.css";

// PDF
import NATcertificateIV from "../../pdf/ISA_10911NAT_Certificate_IV_in_Integrated_Risk_Management.pdf";
import NATdiplomaRisk from "../../pdf/ISA_10941NAT_Diploma_of_Integrated_Risk_Management.pdf";
import NATdipConsultancy from "../../pdf/ISA_10942NAT_DIP_CONSULTANCY.pdf";
import BSBprojectManagement from "../../pdf/ISA_BSB40920_Certificate_IV_in_Project_Management_Practice.pdf";
import BSBdipManagement from "../../pdf/ISA_BSB50820_Dip_Project_Management.pdf";
import BSBprogramManagement from "../../pdf/ISA_BSB60720_Advanced_Diploma_of_Program_Management.pdf";

// Helpers
import { openModal } from "../../helpers/openModal";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";

// Axios and API
import { fetchWorkshops } from "../../api/fetchWorkshops";
import { fetchTestimonials } from "../../api/fetchTestimonials";
// import { fetchTags } from "../../api/fetchTags";

// Moment.js
import moment from "moment";
import { getAPIUrl } from "../../helpers/common";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const Academy = () => {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const { id } = useParams();

  const [onClickedFilterBtn, setOnClickedFilterBtn] = useState(false);
  const [onClickedSortbyBtn, setOnClickedSortbyBtn] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  const [downloadLink, setDownloadLink] = useState("");
  const [course, setCourse] = useState("");

  const [allWorkshops, setAllWorkshops] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  // const [tags, setTags] = useState([]);

  const [maxLengthTestimonialRender, setMaxLengthTestimonialRender] =
    useState(4);

  const [filterValue, setFilterValue] = useState({
    sortById: "DESC",
    sortByWorkshopTitle: null,
    // tags: null,
    typeOfWorkshop: null,
    methodOfLearning: null,
  });

  useEffect(() => {
    fetchAllWorkshops(filterValue);
    fetchAllTestimonials();
    // fetchAllTags();
  }, []);

  // const fetchAllTags = async () => {
  //   const response = await fetchTags();
  //   if (response && response.status === 200 && response.data) {
  //     const { data = [] } = response || {};
  //     const finalTags =
  //       data &&
  //       Array.isArray(data) &&
  //       data.length > 0 &&
  //       data.map((tag) => {
  //         const { tag_name, id } = tag || {};
  //         return { id, label: tag_name, value: tag_name, checked: false };
  //       });
  //     setTags(finalTags);
  //   }
  // };

  const fetchAllWorkshops = async (filter) => {
    const {
      sortById,
      sortByWorkshopTitle,
      // tags,
      typeOfWorkshop,
      methodOfLearning,
    } = filter;
    // let finalTags = [];
    let finalTypeOfWorkshop = [];
    let finalMethodOfLearning = [];

    // if (tags) {
    //   finalTags = Object.values(tags);
    // }

    if (methodOfLearning) {
      for (const key in methodOfLearning) {
        if (methodOfLearning[key]) {
          finalMethodOfLearning.push(key);
        }
      }
    }

    if (typeOfWorkshop) {
      for (const key in typeOfWorkshop) {
        if (typeOfWorkshop[key]) {
          finalTypeOfWorkshop.push(key);
        }
      }
    }

    const response = await fetchWorkshops({
      sortById,
      sortByWorkshopTitle,
      // tags: finalTags,
      typeOfWorkshop: finalTypeOfWorkshop,
      methodOfLearning: finalMethodOfLearning,
    });

    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      const finalWorkshops =
        data && Array.isArray(data) && data.length > 0
          ? data
              .map((workshop) => {
                const {
                  id,
                  cover_image_url,
                  workshop_title,
                  location,
                  input_summary,
                  Schedules = [],
                  AcademyWorkshopTags = [],
                } = workshop || {};
                let temp = Schedules.map((schedule) =>
                  Math.abs(
                    new Date().getTime() -
                      new Date(schedule.start_date).getTime()
                  )
                );
                let idx = temp.indexOf(Math.min(...temp));
                const objWorkshop = {
                  id,
                  coverImage: cover_image_url,
                  tags: AcademyWorkshopTags,
                  scheduleObj: Schedules[idx],
                  location: location,
                  workshopTitle: workshop_title,
                  inputSummary: input_summary,
                  isExpandedInputSummary: false,
                };
                return objWorkshop;
              })
              .sort((a, b) => {
                return (
                  new Date(a.scheduleObj.start_date) -
                  new Date(b.scheduleObj.start_date)
                );
              })
          : [];
      setAllWorkshops(finalWorkshops);
      setWorkshops(finalWorkshops);
    }
  };

  const fetchAllTestimonials = async () => {
    const response = await fetchTestimonials("ASC");
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      setTestimonials(data);
    }
  };

  // open popup after click "Download Brochure"
  const openExpertiseModal = (link) => {
    openModal();
    setDownloadLink(link);
    if (link === BSBdipManagement) {
      setCourse("BSB50820 Diploma of Project Management");
    } else if (link === NATdiplomaRisk) {
      setCourse("10941NAT Diploma of Integrated Risk Management");
    } else if (link === NATcertificateIV) {
      setCourse("10911NAT Certificate IV in Integrated Risk Management");
    } else if (link === BSBprojectManagement) {
      setCourse("BSB40920 Certificate IV in Project Management Practice");
    } else if (link === BSBprogramManagement) {
      setCourse("BSB60720 Advanced Diploma of Program Management");
    } else if (link === NATdipConsultancy) {
      setCourse("10942NAT Diploma of Consultancy");
    }
  };

  const handleOnChangeSearchVal = (e) => {
    let val = e.target.value;
    setSearchVal(val);
    if (val && val !== "") {
      let finalWorkshops = allWorkshops;
      finalWorkshops = finalWorkshops.filter(
        (finalWorkshop) =>
          finalWorkshop.workshopTitle.toLowerCase().includes(`${val}`) ||
          finalWorkshop.inputSummary.toLowerCase().includes(`${val}`)
      );
      setWorkshops(finalWorkshops);
      forceUpdate();
    } else {
      fetchAllWorkshops(filterValue);
    }
  };

  const handleFilterBtn = (e, key1, key2, tagId) => {
    let finalFilter = filterValue;
    let checked = e.target.checked;
    if (key1 === "sortById") {
      finalFilter = {
        ...finalFilter,
        sortById: key2,
        sortByWorkshopTitle: null,
      };
    } else if (key1 === "sortByWorkshopTitle") {
      finalFilter = {
        ...finalFilter,
        sortByWorkshopTitle: key2,
        sortById: null,
      };
    } else if (key1 === "methodOfLearning") {
      finalFilter = {
        ...finalFilter,
        methodOfLearning: {
          ...finalFilter.methodOfLearning,
          [`${key2}`]: checked,
        },
      };
    } else {
      finalFilter = {
        ...finalFilter,
        typeOfWorkshop: { ...finalFilter.typeOfWorkshop, [`${key2}`]: checked },
      };
    }
    // else if (key1 === "tags") {
    //   if (checked) {
    //     finalFilter = {
    //       ...finalFilter,
    //       tags: { ...finalFilter.tags, [`${key2}`]: tagId },
    //     };
    //   } else {
    //     delete finalFilter.tags[`${key2}`];
    //   }
    //   let finalTags = tags.map((tag) => {
    //     if (tag.id === tagId) {
    //       tag.checked = checked;
    //     }
    //     return tag;
    //   });
    //   setTags(finalTags);
    // }
    setFilterValue(finalFilter);
    fetchAllWorkshops(finalFilter);
  };

  const handleReadMoreInputSummary = (workshopId) => {
    let finalWorkshops = workshops;
    finalWorkshops = finalWorkshops.map((workshop) => {
      const { id, isExpandedInputSummary } = workshop || {};
      if (workshopId === id) {
        workshop = {
          ...workshop,
          isExpandedInputSummary: !isExpandedInputSummary,
        };
      }
      return workshop;
    });
    setWorkshops(finalWorkshops);
  };

  const handleDownloadWorkshopRecap = () => {
    window.open(`${getAPIUrl()}/download/isa-workshop-catalog`, "_blank");
  };

  return (
    <div>
      {!id ? (
        <>
          <Header />
          <div className="companyBanner" style={{ paddingTop: "50px" }}>
            <img
              src={CompanyBanner}
              alt="company"
              style={{ objectFit: "cover" }}
            />
            <div className="bannerContentContainer">
              <div className="bannerImageContainer">
                <img src={ISAmobile} alt="rounded ISA" />
              </div>
              <div className="bannerDescContainer title">
                Our workshop series exclusively presented by ISA Academy to help
                you gain knowledge in Sustainability
              </div>
            </div>
          </div>
          <ContainerText
            props={
              <>
                <div
                  className="aboutUsTitleCenter header"
                  style={{ marginBottom: "1em" }}
                >
                  <p>Why join ISA Academy Workshop Series?</p>
                </div>
                <div className="academyContent paragraf">
                  <div
                    className="valuesEachContent"
                    style={{ maxWidth: "200px", margin: "1em" }}
                  >
                    <img
                      src={ISAacademy1}
                      alt="academy"
                      style={{
                        marginBottom: "2em",
                        width: "100%",
                        maxWidth: "150px",
                      }}
                    />
                    <div className="valuesEachContentSubtitle">
                      <p>
                        Conducted by an Instructor who is an expert with
                        professional experiences in assisting multinational and
                        national companies in the formulation of the ESG
                        Roadmap, such as: Vale Inco, Saratoga Investama,
                        Amartha, and PLN.
                      </p>
                    </div>
                  </div>
                  <div
                    className="valuesEachContent"
                    style={{ maxWidth: "200px", margin: "1em" }}
                  >
                    <img
                      src={ISAacademy2}
                      alt="academy"
                      style={{
                        marginBottom: "2em",
                        width: "100%",
                        maxWidth: "150px",
                      }}
                    />
                    <div className="valuesEachContentSubtitle">
                      <p>
                        During the workshop, the participants will conduct
                        hands-on experience, hence they can immediately
                        formulate recommendations for companies guided by the
                        expert instructor.
                      </p>
                    </div>
                  </div>
                  <div
                    className="valuesEachContent"
                    style={{ maxWidth: "200px", margin: "1em" }}
                  >
                    <img
                      src={ISAacademy4}
                      alt="academy"
                      style={{
                        marginBottom: "2em",
                        width: "100%",
                        maxWidth: "150px",
                      }}
                    />
                    <div className="valuesEachContentSubtitle">
                      <p>
                        After the workshop is finished, all knowledge and tools
                        can be directly used for the company.
                      </p>
                    </div>
                  </div>
                  <div
                    className="valuesEachContent"
                    style={{ maxWidth: "200px", margin: "1em" }}
                  >
                    <img
                      src={ISAacademy5}
                      alt="academy"
                      style={{
                        marginBottom: "2em",
                        width: "100%",
                        maxWidth: "150px",
                      }}
                    />
                    <div className="valuesEachContentSubtitle">
                      <p>
                        Learn anywhere, all workshop will be conducted online
                        for student flexibility
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="aboutUsTitleCenter header"
                  style={{ marginBottom: "1em" }}
                >
                  <p>Workshop series</p>
                </div>
                <div
                  className="paragrafWorkshopSeries paragraf"
                  style={{ marginLeft: "3em", marginRight: "3em" }}
                >
                  <p>
                    Join our workshop series and gain sustainability knowledge
                    straight from the experts! Download the full catalog of our
                    workshops here.
                  </p>
                </div>
                <div
                  className="buttonDownloadHereDiplomaESGContainer"
                  style={{ marginBottom: "5em" }}
                >
                  <button
                    className="btn paragraf"
                    onClick={() => handleDownloadWorkshopRecap()}
                  >
                    Download
                  </button>
                </div>
                <div className="filterContent">
                  <div className="searchBarContainerWorkshop">
                    <img src={SearchIcon} alt="Search bar"></img>
                    <input
                      onChange={(e) => handleOnChangeSearchVal(e)}
                      placeholder="Search courses..."
                      type="text"
                    ></input>
                  </div>
                  <div className="filterOptions">
                    <div className="filterOptionsContainer">
                      <button
                        className={
                          onClickedFilterBtn
                            ? "clickedFilterButton"
                            : "filterButton"
                        }
                        onClick={() => {
                          setOnClickedFilterBtn(!onClickedFilterBtn);
                          setOnClickedSortbyBtn(false);
                        }}
                      >
                        <img
                          className="filterButtonImg"
                          src={
                            onClickedFilterBtn ? ClickedFilterIcon : FilterIcon
                          }
                          alt="Filter"
                        ></img>
                        <div
                          className={
                            onClickedFilterBtn
                              ? "clickedFilterButtonDiv"
                              : "filterButtonDiv"
                          }
                        >
                          Filter
                        </div>
                      </button>
                      <button
                        className={
                          onClickedSortbyBtn
                            ? "clickedSortbyButton"
                            : "sortByButton"
                        }
                        onClick={() => {
                          setOnClickedSortbyBtn(!onClickedSortbyBtn);
                          setOnClickedFilterBtn(false);
                        }}
                      >
                        <img
                          src={
                            onClickedSortbyBtn ? ClickedSortbyIcon : SortbyIcon
                          }
                          className="sortByButtonImg"
                          alt="Sort by"
                        ></img>
                        <div
                          className={
                            onClickedSortbyBtn
                              ? "clickedSortbyButtonDiv"
                              : "sortbyButtonDiv"
                          }
                        >
                          Sort by
                        </div>
                      </button>
                    </div>
                    <div>
                      {onClickedFilterBtn && (
                        <div className="dropdownFilterButton">
                          {/* {tags.length > 0 && (
                            <div className="filterBySubject">By Tag</div>
                          )}
                          {tags.length > 0 && (
                            <div className="horizontalLine"></div>
                          )}
                          {tags.length > 0 && (
                            <div className="optionsFilterBtn">
                              {tags.map((tag) => {
                                const { id, value, label, checked } = tag;
                                return (
                                  <div className="eachInput" key={id}>
                                    <input
                                      className="eachInputField"
                                      type="checkbox"
                                      name={`tag${id}`}
                                      checked={checked}
                                      onChange={(e) =>
                                        handleFilterBtn(e, "tags", value, id)
                                      }
                                    />
                                    <label for="vehicle1">{label}</label>
                                  </div>
                                );
                              })}
                            </div>
                          )} */}
                          <div className="filterBySubject">By Category</div>
                          <div className="horizontalLine"></div>
                          <div className="optionsFilterBtn">
                            <div className="eachInput">
                              <input
                                className="eachInputField"
                                type="checkbox"
                                name="Free"
                                checked={
                                  filterValue &&
                                  filterValue.typeOfWorkshop &&
                                  filterValue.typeOfWorkshop["Free"]
                                }
                                onChange={(e) =>
                                  handleFilterBtn(e, "typeOfWork", "Free")
                                }
                              />
                              <label for="free">Free</label>
                            </div>
                            <div className="eachInput">
                              <input
                                className="eachInputField"
                                type="checkbox"
                                name="Paid"
                                onChange={(e) =>
                                  handleFilterBtn(e, "typeOfWork", "Paid")
                                }
                                checked={
                                  filterValue &&
                                  filterValue.typeOfWorkshop &&
                                  filterValue.typeOfWorkshop["Paid"]
                                }
                              />
                              <label for="paid">Paid</label>
                            </div>
                          </div>
                          <div className="filterBySubject">
                            By Method of Learning
                          </div>
                          <div className="horizontalLine"></div>
                          <div className="optionsFilterBtn">
                            <div className="eachInput">
                              <input
                                className="eachInputField"
                                type="checkbox"
                                name="Online"
                                checked={
                                  filterValue &&
                                  filterValue.methodOfLearning &&
                                  filterValue.methodOfLearning["Online"]
                                }
                                onChange={(e) =>
                                  handleFilterBtn(
                                    e,
                                    "methodOfLearning",
                                    "Online"
                                  )
                                }
                              />
                              <label for="Online">Online</label>
                            </div>
                            <div className="eachInput">
                              <input
                                className="eachInputField"
                                type="checkbox"
                                name="Offline"
                                checked={
                                  filterValue &&
                                  filterValue.methodOfLearning &&
                                  filterValue.methodOfLearning["Offline"]
                                }
                                onChange={(e) =>
                                  handleFilterBtn(
                                    e,
                                    "methodOfLearning",
                                    "Offline"
                                  )
                                }
                              />
                              <label for="Offline">Offline</label>
                            </div>
                            <div className="eachInput">
                              <input
                                className="eachInputField"
                                type="checkbox"
                                name="Hybrid (Online and Offline)"
                                checked={
                                  filterValue &&
                                  filterValue.methodOfLearning &&
                                  filterValue.methodOfLearning[
                                    "Hybrid (Online and Offline)"
                                  ]
                                }
                                onChange={(e) =>
                                  handleFilterBtn(
                                    e,
                                    "methodOfLearning",
                                    "Hybrid (Online and Offline)"
                                  )
                                }
                              />
                              <label for="Hybrid (Online and Offline)">
                                {"Hybrid (Online and Offline)"}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {onClickedSortbyBtn && (
                        <div className="dropdownSortbyButton">
                          <button
                            className="optionsSortbyBtn"
                            onClick={(e) =>
                              handleFilterBtn(e, "sortByWorkshopTitle", "DESC")
                            }
                          >
                            A - Z
                          </button>
                          <button
                            className="optionsSortbyBtn"
                            onClick={(e) =>
                              handleFilterBtn(e, "sortByWorkshopTitle", "ASC")
                            }
                          >
                            Z - A
                          </button>
                          <button
                            className="optionsSortbyBtn"
                            onClick={(e) =>
                              handleFilterBtn(e, "sortById", "DESC")
                            }
                          >
                            Newest Added
                          </button>
                          <button
                            className="optionsSortbyBtn"
                            onClick={(e) =>
                              handleFilterBtn(e, "sortById", "ASC")
                            }
                          >
                            Oldest
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="blogCardContainerWorkshop">
                  {workshops.length > 0 ? (
                    workshops.map((workshop) => {
                      const { id, tags, isExpandedInputSummary } =
                        workshop || {};
                      return (
                        <div className="blogCardEachWorkshop" key={workshop.id}>
                          <div className="workshopCardPicture">
                            <img
                              src={ISA_academy_detail_background}
                              alt="workshop background"
                              className="academyDetailImageBackground"
                            />
                            <img
                              src={workshop.coverImage}
                              onClick={() =>
                                navigate(`/Academy/details/${workshop.id}`)
                              }
                              alt="academy"
                              className="workshopCardPictureMainImage"
                            />
                          </div>
                          <div
                            className="blogCardTitle title"
                            onClick={() =>
                              navigate(`/Academy/details/${workshop.id}`)
                            }
                          >
                            <p>
                              {capitalizeFirstLetter(workshop.workshopTitle)}
                            </p>
                          </div>
                          {/* <p
                            style={{
                              fontSize: "14px",
                              color: "#104c84",
                              textAlign: "left",
                              marginBottom: 0,
                            }}
                          >
                            Workshop Date/Time
                          </p>
                          <div
                            className="blogCardDate"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            <p>
                              <strong>{value.date}</strong>
                            </p>
                          </div> */}
                          <div className="blogCardDate">
                            <img src={DateIcon} alt="academy"></img>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                className="paragraf"
                                style={{
                                  marginLeft: "0",
                                  marginRight: "1em",
                                  fontWeight: "bold",
                                }}
                              >
                                {moment(workshop.scheduleObj.start_date).format(
                                  "dddd, MMMM Do YYYY"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="blogCardMethod">
                            <img src={Method} alt="academy"></img>
                            <div className="paragraf">
                              {workshop.scheduleObj.method_of_learning}
                            </div>
                          </div>
                          <div className="blogCardLocation">
                            <img src={Location} alt="academy"></img>
                            <div className="paragraf">
                              {capitalizeFirstLetter(workshop.location)}
                            </div>
                          </div>
                          <div
                            className="blogCardSubtitle paragraf"
                            style={{
                              lineHeight: "30px",
                              marginTop: "2em",
                            }}
                          >
                            <p>
                              {isExpandedInputSummary
                                ? workshop.inputSummary
                                : `${workshop.inputSummary.substring(
                                    0,
                                    150
                                  )}...`}
                              <span
                                onClick={() => handleReadMoreInputSummary(id)}
                                style={{ color: "blue", cursor: "pointer" }}
                              >
                                {isExpandedInputSummary
                                  ? " See Less"
                                  : " Read More"}
                              </span>
                            </p>
                          </div>
                          {/* {tags && Array.isArray(tags) && tags.length > 0 ? (
                            <div style={{ marginTop: "20px" }}>
                              <div className="blogCardTags">Tags:</div>
                              {tags.map((tag, index) => {
                                const { Tag: { tag_name } = {} } = tag;
                                return (
                                  <div
                                    className="blogCardTagsBoxContainer"
                                    key={index}
                                  >
                                    <div className="blogCardTagsBox">
                                      <p>{tag_name}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null} */}
                          <div className="ISAcompanyButton">
                            <button
                              type="button"
                              className="btn btn-warning paragrafDesc"
                              onClick={() =>
                                navigate(`/Academy/details/${workshop.id}`)
                              }
                            >
                              View details
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : workshops.length <= 0 &&
                    !searchVal &&
                    filterValue &&
                    !filterValue.sortByWorkshopTitle &&
                    !filterValue.typeOfWorkshop &&
                    !filterValue.methodOfLearning ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        marginTop: "-2em",
                        marginLeft: "5em",
                      }}
                    >
                      <div style={{ marginBottom: "3em" }}>
                        <img
                          src={NoWorkshopAvailable}
                          alt="Data not found icon"
                          width={200}
                        />
                      </div>
                      <h4
                        style={{
                          color: "#000000",
                        }}
                      >
                        No workshop available at this moment. Stay tune!
                      </h4>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        marginTop: "-2em",
                        marginLeft: "5em",
                      }}
                    >
                      <div style={{ marginBottom: "3em" }}>
                        <img
                          src={NotFound}
                          alt="Data not found icon"
                          width={200}
                        />
                      </div>
                      <h4
                        style={{
                          color: "#000000",
                        }}
                      >
                        No workshops were found to match your search
                      </h4>
                    </div>
                  )}
                </div>
                <div className="aboutUsTitleCenter header">
                  <p>Workshop Participant Testimonial</p>
                </div>
                <ContainerText
                  props={
                    <div className="testimonialMembersContainer">
                      <div className="eachMembers">
                        <div className="eachMembersDescContainer">
                          <div style={{ maxWidth: "300px" }}>
                            <div className="eachMembersPicture">
                              <img
                                src={workshop_testimonial_Achmad_Siswandy_Asdam}
                                alt={"testimonialImage"}
                              />
                            </div>
                            <div
                              className="internName paragraf"
                              style={{ marginTop: "1em" }}
                            >
                              <p>Achmad Siswandy Asdam</p>
                            </div>
                            <div className="eachMembersTitle paragrafDesc">
                              <p>Project Engineer Vale Inco</p>
                            </div>
                          </div>
                          <div className="containerDesc paragrafDesc">
                            <div
                              className="eachMembersDesc"
                              style={{ textAlign: "justify" }}
                            >
                              <p>
                                I'm impressed about how every engineering
                                economics, business and strategic management
                                tools applicable in every single process in SROI
                                as the bottom line for our plan execution
                                monitoring and control ESG Implementation. As a
                                result we get reliable result. Things that
                                really left a mark on me during the workshop how
                                the mentors give clear explanation and easy to
                                understand, and related with best practice.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="eachMembers">
                        <div className="eachMembersDescContainer">
                          <div style={{ maxWidth: "300px" }}>
                            <div className="eachMembersPicture">
                              <img
                                src={workshop_testimonial_Gandung_Pratidhina}
                                alt={"testimonialImage"}
                              />
                            </div>
                            <div
                              className="internName paragraf"
                              style={{ marginTop: "0.5em" }}
                            >
                              <p>Gandung Pratidhina</p>
                            </div>
                            <div className="eachMembersTitle paragrafDesc">
                              <p>Manager Planning & Development CSR TELKOM</p>
                            </div>
                          </div>
                          <div className="containerDesc paragrafDesc">
                            <div
                              className="eachMembersDesc"
                              style={{ textAlign: "justify" }}
                            >
                              <p>
                                The training "ESG, CSR, GRI, and SDGs Concept,
                                Issues and Context: With Case Studies" at ISA
                                Academy has <b>changed the way I see</b>{" "}
                                Corporate Social Responsibility Programs and{" "}
                                <b>their impact in a sustainable context</b>.
                                <br />
                                <br />
                                Not only do I understand these concepts better,
                                but I also <b>have deeper insight</b> into how
                                they can be applied in the real world.
                                <br />
                                <br />
                                <b>
                                  The speakers presented were very enlightening
                                </b>{" "}
                                and <b>provided insight</b> into the challenges
                                and opportunities faced by organizations when
                                trying to achieve sustainable goals.
                                <br />
                                <br />
                                This training really broadened my horizons about
                                the role of TJSL amidst global and local
                                dynamics in aspects of business, society and the
                                environment.
                                <br />
                                <br />
                                Thank you ISA Academy for this truly inspiring
                                training.
                              </p>
                              {/* <p className="testimonialWorkshopTitleFont">
                                {workshop_title}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="eachMembers">
                        <div className="eachMembersDescContainer">
                          <div style={{ maxWidth: "300px" }}>
                            <div className="eachMembersPicture">
                              <img
                                src={workshop_testimonial_Annette_Apriliana}
                                alt={"testimonialImage"}
                              />
                            </div>
                            <div
                              className="internName paragraf"
                              style={{ marginTop: "1em" }}
                            >
                              <p>Annette Aprilana</p>
                            </div>
                            <div className="eachMembersTitle paragrafDesc">
                              <p>
                                ESG & Sustainability Lead - Funding Societies |
                                Modalku
                              </p>
                            </div>
                          </div>
                          <div className="containerDesc paragrafDesc">
                            <div
                              className="eachMembersDesc"
                              style={{ textAlign: "justify" }}
                            >
                              <p>
                                The ISA training on "ESG, CSR, GRI and SDGs
                                Concept, Issues and Context: with Case Studies"
                                organised by ISA was a valuable course that
                                provided clear explanations and distinctions
                                between the different concepts. The one-day
                                workshop was well-timed and well-structured,
                                with enough room for all participants to ask
                                questions or share their reflections after each
                                segment. Each speaker was very engaging in
                                delivering the materials and were subject-matter
                                experts within their respective fields.
                                <br />
                                <br />
                                The workshop is particularly helpful for those
                                that want to understand the big picture and how
                                the concepts relate to each other, while at the
                                same time receive Indonesia-specific examples.
                                Expect to find a balanced mix between learning
                                about the philosophy of the topics as well as
                                the technical details.
                                <br />
                                <br />
                                With rapid developments surrounding the topic of
                                ESG and sustainability, it becomes more
                                important for sustainability practitioners or
                                enthusiasts to stay updated and not share
                                imprecise information. I recommend this workshop
                                to individuals that want a compact yet thorough
                                and informative session on sustainability and
                                its related concepts.
                              </p>
                              {/* <p className="testimonialWorkshopTitleFont">
                                {workshop_title}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
                {testimonials.length > 0 && (
                  <div className="aboutUsTitleCenter header">
                    <p>ISA Fellows</p>
                  </div>
                )}
                {testimonials &&
                  Array.isArray(testimonials) &&
                  testimonials.length > 0 && (
                    <ContainerText
                      props={
                        <div className="testimonialMembersContainer">
                          {testimonials.map((testimonial, index) => {
                            const {
                              id,
                              workshop_title,
                              participant_name,
                              participant_details,
                              image_url,
                            } = testimonial;
                            if (index + 1 <= maxLengthTestimonialRender) {
                              return (
                                <div className="eachMembers" key={id}>
                                  <div className="eachMembersDescContainer">
                                    <div style={{ maxWidth: "300px" }}>
                                      <div className="eachMembersPicture">
                                        <img
                                          src={image_url}
                                          alt={"testimonialImage"}
                                        />
                                      </div>
                                      <div
                                        className="internName paragraf"
                                        style={{ marginTop: "1em" }}
                                      >
                                        <p>{participant_name}</p>
                                      </div>
                                      <div className="eachMembersTitle paragrafDesc">
                                        <p>{participant_details}</p>
                                      </div>
                                    </div>
                                    <div className="containerDesc paragrafDesc">
                                      <div className="eachMembersDesc">
                                        <p>{testimonial.testimonial}</p>
                                        {/* <p className="testimonialWorkshopTitleFont">
                                      {workshop_title}
                                    </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      }
                    />
                  )}

                {testimonials.length > 0 &&
                  testimonials.length > maxLengthTestimonialRender && (
                    <div
                      className="btnViewMore"
                      onClick={() => {
                        setMaxLengthTestimonialRender(
                          maxLengthTestimonialRender + 4
                        );
                        fetchAllTestimonials();
                      }}
                    >
                      <div>View more</div>
                    </div>
                  )}
              </>
            }
          />
          <ContainerText
            props={
              <div className="expertiseSubContainer">
                <div
                  id="VocationalStudies"
                  className="expertiseSubTitle headerDescription"
                >
                  <p>Vocational Studies</p>
                </div>
                <div
                  style={{
                    marginBottom: "1em",
                  }}
                >
                  <img
                    src={VocationalSectionLogo}
                    alt="vocational logo"
                    style={{ width: "35%", height: "35%" }}
                  />
                </div>
                <div className="expertiseChildrenTitle paragraf">
                  <p>
                    The recommended pathway for students and professionals that
                    would like to enhance their education, learn specific and
                    practical job skills, especially in the workforce. We are{" "}
                    <strong>
                      partnering with Australia-based Polytechnic, Interlink
                      Technology Services Pty Ltd
                    </strong>{" "}
                    RTO 31926{" "}
                    <a
                      href="https://www.itpl.com.au"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.itpl.com.au
                    </a>{" "}
                    to provide certifications and qualifications in:
                  </p>
                </div>
                <Accordion defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fa fa-angle-right" />
                      </span>
                      <div style={{ marginTop: "-0.15em" }} className="title">
                        BSB50820 Diploma of Project Management
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="paragraf">
                      Project Management is an established industry discipline,
                      and skilled practitioners are in strong demand from
                      organizations across all sectors. Talented and
                      professionally trained Project Managers bring real value
                      to organizations. From time management to human resources,
                      or even communications and procurement, this Diploma
                      course will help you in becoming a knowledgeable Project
                      Manager. <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() => openExpertiseModal(BSBdipManagement)}
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fa fa-angle-right" />
                      </span>
                      <div style={{ marginTop: "-0.15em" }} className="title">
                        10941NAT Diploma of Integrated Risk Management
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="paragraf">
                      Enterprise Risk Management (ERM) is the process of
                      identifying potential threats and opportunities that may
                      impact an organization, and preparing for them.
                      <br />
                      <br />
                      Integrated Risk Management (IRM) takes place when ERM is
                      fully integrated at all levels and functions within an
                      organization. IRM benefits all organizations, regardless
                      of size, as a robust IRM culture brings value and
                      strengthened business resilience.
                      <br />
                      <br />
                      Organizations that apply an integrated approach within
                      their business structure enjoy stability, security and
                      peace of mind. IRM allows businesses to react quickly and
                      appropriately to challenges, and the process of Risk
                      Identification also places organizations in a stronger
                      position to identify opportunity and enhance returns.
                      <br />
                      <br />
                      Here we are able to give you the necessary knowledge in
                      order to implement IRM in your company / organization.
                      <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() => openExpertiseModal(NATdiplomaRisk)}
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fa fa-angle-right" />
                      </span>
                      <div style={{ marginTop: "-0.15em" }} className="title">
                        10911NAT Certificate IV in Integrated Risk Management
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="paragraf">
                      The 10911NAT Certificate IV in Integrated Risk Management
                      is designed to introduce candidates to the fundamentals of
                      Risk Management.
                      <br />
                      <br />
                      The course is ideal for staff members who support or apply
                      Risk Management in a functional role within their
                      organization. Practical hands-on training is delivered
                      utilising a range of tools within the training workshops.
                      <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() => openExpertiseModal(NATcertificateIV)}
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fa fa-angle-right" />
                      </span>
                      <div style={{ marginTop: "-0.15em" }} className="title">
                        BSB40920 Certificate IV in Project Management Practice
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="paragraf">
                      This is a course developed specifically for project
                      coordinators and team members with a detailed overview of
                      fundamentals.
                      <br />
                      <br />
                      With this course we aim to provide our candidates with the
                      best practice knowledge and skills that are necessary to
                      contribute to the management of a project, or take
                      responsibility for components of larger projects.
                      Candidates will also learn of sound practical
                      understanding of project principles and methodologies
                      <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            openExpertiseModal(BSBprojectManagement)
                          }
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fa fa-angle-right" />
                      </span>
                      <div style={{ marginTop: "-0.15em" }} className="title">
                        BSB60720 Advanced Diploma of Program Management
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="paragraf">
                      This is a program with a scope set for Program Managers or
                      Project Directors, and it aims to provide essential skills
                      to navigate programs of projects Successfully negotiate
                      key challenges facing Program Managers.
                      <br />
                      <br />
                      Graduates will be equipped to: Implement best practice;
                      Direct programs of multiple projects; Actively manage
                      complex and competing schedules; Display outstanding
                      leadership. <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            openExpertiseModal(BSBprogramManagement)
                          }
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fa fa-angle-right" />
                      </span>
                      <div style={{ marginTop: "-0.15em" }} className="title">
                        10942NAT Diploma of Consultancy
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="paragraf">
                      Consultants are recognised experts within their chosen
                      field and provide clients with a highly specialised,
                      professional service, which draws on their considerable
                      experience and qualifications. Today’s consultant works in
                      a wide range of professions, such as human resources,
                      information technology, financial management, project
                      management, marketing, and many more. The world moves in
                      one direction, forward. And in that direction, things
                      constantly interconnect with each other and new challenges
                      arise. In order to tread correctly to not only pass by but
                      also succeed in their respective fields, organizations and
                      companies are constantly in demand of consultants with
                      foresight and the readiness to face whatever comes their
                      way. We’re ready to equip you with said foresight and
                      readiness. <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() => openExpertiseModal(NATdipConsultancy)}
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            }
          />
          <Footer />
        </>
      ) : (
        <>
          <Header />
          <div
            style={{
              marginLeft: "2em",
              marginRight: "2em",
            }}
          >
            <ContainerText
              props={
                <>
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#104c84",
                      paddingTop: "150px",
                      textAlign: "left",
                    }}
                  >
                    Workshop topic
                  </div>
                  <div className="academyTitle">
                    <p>{parse(workshops[id - 1].workshopTitle)}</p>
                  </div>
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#104c84",
                      textAlign: "left",
                    }}
                  >
                    Workshop date
                  </div>
                  <div
                    style={{
                      fontSize: "22px",
                      marginBottom: "2.5em",
                      textAlign: "left",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ marginLeft: "0", marginRight: "1em" }}>
                        {moment(
                          workshops[id - 1].scheduleObj.start_date
                        ).format("DD/MM/YYYY")}
                      </div>
                      <div>
                        {moment(
                          workshops[id - 1].scheduleObj.start_date
                        ).format("HH:mm")}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <i>
                      *) The online workshop will be conducted in
                      <strong>Bahasa and English</strong> (depending on the
                      participants)
                    </i>
                  </div>
                  <Accordion
                    defaultActiveKey={["0", "1", "2", "3", "4"]}
                    alwaysOpen
                    style={{ textAlign: "left" }}
                  >
                    <Accordion.Item eventKey="0">
                      <div style={{ marginTop: "1em" }}>
                        ESG has been under the spotlight for Investors and
                        Rating Agencies. Investors are using ESG Performance to
                        own shares, whilst Rating Agencies analyse the ESG
                        Performance to produce ratings and make decisions to
                        include or exclude corporates in the ESG-related Index
                        lists at the IDX. Failure to be included in The Index
                        lists makes the corporate's shares less appealing to
                        future investors and existing shareholders may sell
                        their shares. Hence, it is imperative to understand ESG
                        and its impact on the corporate's overall reputation and
                        how to enhance ESG performance.
                      </div>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        What you will learn?
                      </Accordion.Header>
                      <Accordion.Body>
                        <strong>Workshop Materials:</strong>
                        <br />
                        <strong>Day 1:</strong>
                        <ol>
                          <li>
                            ESG, ISO 26000, CSV (Creating Shared Value): what,
                            why, and examples of its application
                          </li>
                          <li>ESG Ratings</li>
                          <li>The link between ESG, ISO 26000, CSV</li>
                          <li>
                            Linking Targets and Indicators on SDGs with ESG, ISO
                            26000 and CSV
                          </li>
                          <li>
                            Exercise: Formulating the materiality of the SDGs
                          </li>
                          <li>
                            Exercise: Conduct a self-assessment of your
                            company's ESG performance
                          </li>
                        </ol>
                        <strong>Day 2:</strong>
                        <ol>
                          <li>Choosing the right ESG standard</li>
                          <li>Formulating ESG materiality</li>
                          <li>
                            What is needed to improve ESG reputation in the eyes
                            of investors and ESG Rating Agencies
                          </li>
                          <li>
                            Exercise: Formulate recommendations to improve your
                            company's ESG performance
                          </li>
                          <li>
                            Exercise: Formulate recommendations for strategic
                            CSR for your company, namely those that achieve good
                            ESG performance, follow ISO 26000, create
                            Shared-Value, and contribute to the right SDGs.
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        ISA Academy
                      </Accordion.Header>
                      <Accordion.Body>
                        <strong>Our Pre-eminence:</strong>
                        <br />
                        <ol>
                          <li>
                            Conducted by Instructors who are expert with
                            professional experiences in assisting multinational
                            and national companies in the formulation of the ESG
                            Roadmap, such as: Vale Inco, Saratoga Investama,
                            Amartha, and PLN.
                          </li>
                          <li>
                            During the workshop, the participants will conduct
                            hands-on experience, hence they can immediately
                            formulate recommendations for companies guided by
                            the expert instructor.
                          </li>
                          <li>
                            After the workshop is finished, all knowledge and
                            tools can be directly used for the company.
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        What you will get?
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>Materials that can be used as a company guide</li>
                          <li>
                            Tools that can be used directly by the company
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        Target Audience
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>
                            State-Owned Enterprises, Private Sector and Civil
                            Society
                          </li>
                          <li>ESG and CSR practitioners</li>
                          <li>Sustainability Report writer</li>
                          <li>
                            Students of Master Degree, Doctorate or PhD Programs
                          </li>
                          <li>General Managers</li>
                          <li>Public Relations Managers</li>
                          <li>
                            Other practitioners who are interested in ESG, ISO
                            26000, CSV
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        Investment
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Two-day (2) workshop: IDR 5.300.000</p>
                        <strong>Packages for organisations' group:</strong>
                        <ul>
                          <li>IDR 15.100.000 for 3 participants</li>
                          <li>IDR 23.800.000 for 5 participants</li>
                          <li>IDR 50.100.000 for 10 participants</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        Method of training and how to register?
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <strong>Method of training:</strong> Zoom Call
                            Application
                            <span>
                              <img
                                src={ZoomIcon}
                                width={30}
                                height={30}
                                style={{ marginLeft: "0.5em" }}
                                alt="zoom icon"
                              />
                            </span>
                          </li>
                          <li>
                            <strong>How to register:</strong>
                            <ol>
                              <li>
                                Fill in registration form in this link &nbsp;
                                <a
                                  href="https://docs.google.com/forms/d/e/1FAIpQLSfYqhNZynuCSKi_vo2U1Q_mvaNu__r1BMgm4kVmSN2cCmRX1A/viewform?usp=sf_link"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  HERE
                                </a>
                              </li>
                              <li>
                                Once submitted, you will receive confirmation
                                email for the next steps
                              </li>
                            </ol>
                          </li>
                        </ul>
                        <strong>
                          For further information, please contact:
                        </strong>
                        <br />
                        <br />
                        <span>WhatsApp: </span>
                        <div className="contactWhatsAppButton">
                          <a
                            href="https://api.whatsapp.com/send/?phone=6281218467661&text&app_absent=0"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              style={{ color: "black" }}
                              src={ChatBubble}
                              alt="chat bubble"
                            />
                            +6281218467661 (Billy)
                          </a>
                        </div>
                        <br />
                        <span>
                          Email: &nbsp;
                          <a
                            className="academyEmail"
                            href="mailto:isa-academy@sustainability.co.id"
                          >
                            isa-academy@sustainability.co.id
                          </a>
                        </span>
                        <br />
                        <br />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <div
                        className="contactFormSubmit"
                        style={{
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "center",
                          marginBottom: "3em",
                        }}
                      >
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSfYqhNZynuCSKi_vo2U1Q_mvaNu__r1BMgm4kVmSN2cCmRX1A/viewform?usp=sf_link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button
                            className="btn btn-warning"
                            style={{
                              padding: "1em",
                              fontSize: "20px",
                            }}
                          >
                            <img
                              src="https://cdn.discordapp.com/attachments/986485246256099389/996327208241074236/edit.png"
                              alt="register"
                              width={30}
                              height={30}
                              style={{
                                marginRight: "0.5em",
                              }}
                            />
                            Register here
                          </button>
                        </a>
                      </div>
                    </Accordion.Item>
                  </Accordion>
                </>
              }
            />
            <ContainerText
              props={
                <i>
                  <hr />
                  <Accordion
                    defaultActiveKey={["0", "1", "2", "3", "4"]}
                    alwaysOpen
                    style={{ textAlign: "left" }}
                  >
                    <Accordion.Item eventKey="0">
                      ESG telah menjadi sorotan bagi Investor dan Lembaga
                      Pemeringkat. Investor menggunakan Kinerja LST untuk
                      memiliki saham, sementara Lembaga Pemeringkat menganalisis
                      Kinerja LST untuk menghasilkan peringkat dan membuat
                      keputusan untuk memasukkan atau mengecualikan perusahaan
                      dalam daftar Indeks terkait LST di BEI. Kegagalan untuk
                      dimasukkan dalam daftar Indeks membuat saham perusahaan
                      kurang menarik bagi investor masa depan dan pemegang saham
                      yang ada dapat menjual saham mereka. Oleh karena itu,
                      sangat penting untuk memahami LST dan dampaknya terhadap
                      reputasi perusahaan secara keseluruhan dan bagaimana
                      meningkatkan kinerja LST.
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        Apa yang akan anda pelajari?
                      </Accordion.Header>
                      <Accordion.Body>
                        <strong>Hari-1:</strong>
                        <ol>
                          <li>
                            ESG, ISO 26000, CSV: apa, mengapa, contoh-contoh
                            penerapan
                          </li>
                          <li>ESG Ratings</li>
                          <li>Kaitan antara ESG, ISO 26000, CSV</li>
                          <li>
                            Mengkaitkan Target dan Indikator pada SDGs dengan
                            ESG, ISO 26000 dan CSV
                          </li>
                          <li>Merumuskan materialitas SDGs</li>
                          <li>
                            Melakukan self-assessment atas kinerja ESG
                            perusahaan anda
                          </li>
                        </ol>
                        <strong>Hari-2:</strong>
                        <ol>
                          <li>Memilih standar ESG yang tepat</li>
                          <li>Merumuskan materialitas ESG</li>
                          <li>
                            Apa yang diperlukan untuk meningkatkan reputasi ESG
                            di mata investor dan ESG Rating Agencies
                          </li>
                          <li>
                            Merumuskan rekomendasi untuk meningkatkan kinerja
                            ESG perusahaan anda
                          </li>
                          <li>
                            Merumuskan rekomendasi untuk CSR yang strategic bagi
                            perusahaan anda, yakni yang mencapai kinerja baik
                            ESG, mengikuti ISO 26000, menciptakan Shared Value
                            dan berkontribusi pada SDGs yang tepat.
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        Keunggulan
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>
                            Instruktur pakar yang kompeten dengan pengalaman
                            membantu perusahaan multinasional dan nasional di
                            bidang perumusan Roadmap ESG, seperti: Vale Inco,
                            Saratoga Investama, Amartha, PLN
                          </li>
                          <li>
                            Mendapatkan materi yang dapat digunakan sebagai
                            panduan perusahaan
                          </li>
                          <li>
                            Mendapatkan tools yang dapat langsung digunakan
                            perusahaan
                          </li>
                          <li>
                            Melakukan latihan langsung pada saat workshop,
                            sehingga peserta dapat segera merumuskan rekomendasi
                            untuk perusahaan dipandu oleh Instruktur pakar
                          </li>
                          <li>
                            Setelah workshop selesai, semua pengetahuan dan
                            tools dapat langsung digunakan untuk perusahaan
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        Investasi
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Workshop 2 (dua) hari: IDR 5.300.000</p>
                        <strong>Paket untuk organisasi:</strong>
                        <ul>
                          <li>IDR 15.100.000 untuk 3 peserta</li>
                          <li>IDR 23.800.000 untuk 5 peserta</li>
                          <li>IDR 50.100.000 untuk 10 peserta</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <span className="expertiseArrowRight">
                          <i className="fa fa-angle-right" />
                        </span>
                        Metode pelatihan dan bagaimana cara mendaftar?
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <strong>Metode pelatihan:</strong> Zoom Call
                            Application
                            <span>
                              <img
                                src={ZoomIcon}
                                width={30}
                                height={30}
                                style={{ marginLeft: "0.5em" }}
                                alt="zoom icon"
                              />
                            </span>
                          </li>
                          <li>
                            <strong>Cara mendaftar:</strong>
                            <ol>
                              <li>
                                Isi formulir registrasi pada &nbsp;
                                <a
                                  href="https://docs.google.com/forms/d/e/1FAIpQLSfYqhNZynuCSKi_vo2U1Q_mvaNu__r1BMgm4kVmSN2cCmRX1A/viewform?usp=sf_link"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  link ini
                                </a>
                              </li>
                              <li>
                                Ketika berhasil terkirim, anda akan mendapatkan
                                konfirmasi email untuk langkah selanjutnya
                              </li>
                            </ol>
                          </li>
                        </ul>
                        <strong>
                          Untuk informasi lebih lanjut, silahkan hubungi:
                        </strong>
                        <br />
                        <br />
                        <span>WhatsApp: </span>
                        <div className="contactWhatsAppButton">
                          <a
                            href="https://api.whatsapp.com/send/?phone=6281218467661&text&app_absent=0"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              style={{ color: "black" }}
                              src={ChatBubble}
                              alt="chat bubble"
                            />
                            +6281218467661 (Billy)
                          </a>
                        </div>
                        <br />
                        <span>
                          Email: &nbsp;
                          <a
                            className="academyEmail"
                            href="mailto:isa-academy@sustainability.co.id"
                          >
                            isa-academy@sustainability.co.id
                          </a>
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <br />
                </i>
              }
            />
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Academy;
