import { useEffect, useState } from "react";

// React DOM
import { useNavigate, useParams } from "react-router-dom";

// Axios
import Axios from "../../api/axios";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// Icons & Backgrounds
import LinkedInBlackLogo from "../../images/icons/LinkedInBlack.png";

// CSS styling
import "../../styles/about.css";
import "../../styles/previousButton.css";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const TeamDetail = () => {
  const navigate = useNavigate();
  const [teamMember, setTeamMember] = useState([]);
  const { id } = useParams();

  const fetchMember = async () => {
    const response = await Axios.get(`/teamMember/${id}`).catch((err) => {
      console.log("Err: ", err);
    });
    setTeamMember(response.data);
  };

  useEffect(() => {
    fetchMember();
    Aos.init({ duration: 1000 });
  }, [id]);

  return (
    <div>
      <Header />
      <ContainerText
        props={
          <div id="theFounder">
            <div className="founderProfileContainer">
              <div
                className="previousButton"
                onClick={() => navigate("/About/TeamMembers")}
              >
                <span className="expertiseArrowRight">
                  <i className="fas fa-angle-left" />
                </span>
                <a href="">Back to previous page</a>
              </div>
              <div className="founderProfileBio">
                <div className="founderProfileBioPicture">
                  <img src={teamMember.picture} alt="founder" />
                </div>
                <div className="founderProfileBioText">
                  <div className="founderProfileBioTextName title">
                    <p>{teamMember.name}</p>
                  </div>
                  <div className="founderProfileBioTextTitle paragraf">
                    <p>{teamMember.jobTitle}</p>
                  </div>
                  <div className="founderProfileBioTextLinkedIn">
                    <a
                      href={teamMember.linkedIn}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={LinkedInBlackLogo} alt="LinkedInFounder" />
                    </a>
                    <a
                      href={teamMember.linkedIn}
                      target="_blank"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="aboutFounder">
              <div className="aboutFounderMainContainer">
                <div className="founderAbout">
                  <div className="aboutUsTitle headerDescription">
                    <p>About</p>
                    <hr />
                  </div>
                  <div className="aboutText paragraf">
                    <p>{teamMember.description}</p>
                  </div>
                </div>
                <div
                  className="founderPublishedWorks"
                  style={{ display: "none" }}
                >
                  <div className="aboutUsTitle">
                    <p>Published Works</p>
                    <hr />
                  </div>
                  <div
                    className="publishedWorksList"
                    style={{ display: "none" }}
                  >
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                        <a href="">click here to download</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="aboutFounderSpecialisation"
                style={{ display: "none" }}
              >
                <div className="specialisationTitle">
                  <p>Specialisation</p>
                </div>
                <div className="specialisationList">
                  <ul>
                    <li>Corporate Social Responsibility (CSR)</li>
                    <li>Sustainability</li>
                    <li>Environmental, Social and Governance (ESG)</li>
                    <li>Inclusive Finance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Footer />
    </div>
  );
};

export default TeamDetail;
