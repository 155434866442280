import React, { useEffect, useState } from "react";

// React Navigation
import { useNavigate } from "react-router-dom";

// Moment.js
import moment from "moment";

// Axios
import Axios from "../../api/axios";

// Apis
import { fetchWorkshops } from "../../api/fetchWorkshops";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// CSS styling
import "../../styles/home.css";

// React Slideshow Image
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// react responsive
import { useMediaQuery } from "react-responsive";

// helpers
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";

// Icons, Background, Card, and Logo
import CCSlogo from "../../images/companyLogo/CCSblack-Logo.png";
import SideBanner1 from "../../images/background/ISA_WEB_BANNER_1.jpg";
import SideBanner2 from "../../images/background/ISA_WEB_BANNER_2.jpg";
import CardProgram1 from "../../images/programCards/program-card-1.png";
import CardProgram2 from "../../images/programCards/program-card-2.png";
import CardProgram3 from "../../images/programCards/program-card-3.jpg";
import CardProgram4 from "../../images/programCards/program-card-4.png";
import landing_page_infographic_banner from "../../images/diplomaESG/landing_page_infographic_banner.png";
import DateIcon from "../../images/icons/Date.png";
import Method from "../../images/icons/Method.png";
import Location from "../../images/icons/Location.png";
import ISA_academy_detail_background from "../../images/background/ISA_academy_detail_background.jpeg";

// Components
import PaginatedItems from "../../components/Pagination";
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LearningVideosContent from "../../components/LearningVideosContent";

// banner
import banner_utama from "../../images/eventCards/banner_utama.jpeg";

const HomeBanner =
  "https://ik.imagekit.io/4n54l5tm8yf/ISA/HomeBanner_E_t-ik9NY.png?ik-sdk-version=javascript-1.4.3&updatedAt=1650859450189";

const Home = () => {
  const navigate = useNavigate();

  const isMobileSize = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabSize = useMediaQuery({
    query: "(min-width: 641px) and (max-width: 820px)",
  });

  const [bannerImages] = useState([
    { img_url: banner_utama, learn_more_url: "/Diploma-PM-for-ESG" },
    { img_url: HomeBanner, learn_more_url: "/Expertise" },
  ]);

  const [news, setNews] = useState([]);
  const [clients, setClients] = useState([]);
  const [upcomingWorkshop, setUpcomingWorkshop] = useState([]);

  const fetchClients = async () => {
    const response = await Axios.get("/client").catch((err) => {
      console.log("Err: ", err);
    });
    setClients(response.data);
  };

  const fetchNews = async () => {
    const response = await Axios.get("/news/updatedLimit").catch((err) => {
      console.log("Err: ", err);
    });
    setNews(response.data);
  };

  const fetchAllWorkshops = async () => {
    const response = await fetchWorkshops({
      sortById: "DESC",
      displayOnHomePage: true,
    });

    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      const finalWorkshops =
        data && Array.isArray(data) && data.length > 0
          ? data
              .map((workshop) => {
                const {
                  id,
                  cover_image_url,
                  workshop_title,
                  location,
                  Schedules = [],
                  AcademyWorkshopTags = [],
                } = workshop || {};
                let temp = Schedules.map((schedule) =>
                  Math.abs(
                    new Date().getTime() -
                      new Date(schedule.start_date).getTime()
                  )
                );
                let idx = temp.indexOf(Math.min(...temp));
                const objWorkshop = {
                  id,
                  coverImage: cover_image_url,
                  tags: AcademyWorkshopTags,
                  scheduleObj: Schedules[idx],
                  location: location,
                  workshopTitle: workshop_title,
                };
                return objWorkshop;
              })
              .sort((a, b) => {
                return (
                  new Date(a.scheduleObj.start_date) -
                  new Date(b.scheduleObj.start_date)
                );
              })
          : [];
      setUpcomingWorkshop(finalWorkshops);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
    fetchClients();
    fetchNews();
    fetchAllWorkshops();
  }, []);

  const buttonStyle = {
    position: "absolute",
    zIndex: "1",
    cursor: "pointer",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    color: "#000000",
    fontSize: "80px",
    padding: "20px",
    borderRadius: "50%",
  };

  const properties = {
    prevArrow: (
      <button
        className="arrow arrow-left"
        style={{ ...buttonStyle, left: 30 }}
        hidden
      >
        <span>&lt;</span>
      </button>
    ),
    nextArrow: (
      <button
        className="arrow arrow-right"
        style={{ ...buttonStyle, right: 30 }}
        hidden
      >
        <span>&gt;</span>
      </button>
    ),
  };

  return (
    <div id="home">
      {/* Home Banner */}
      <Header />
      <div id="homeBanner">
        <Slide {...properties}>
          {bannerImages.map((slideImage, idx) => {
            const { img_url, learn_more_url } = slideImage;
            return (
              <div
                key={idx}
                onClick={() => (idx === 0 ? navigate(learn_more_url) : null)}
              >
                <div
                  style={
                    isMobileSize
                      ? {
                          marginTop: "3vh",
                          height: "auto",
                          cursor: idx === 0 ? "pointer" : null,
                        }
                      : isTabSize
                      ? {
                          marginTop: "8vh",
                          height: "500px",
                          cursor: idx === 0 ? "pointer" : null,
                        }
                      : {
                          marginTop: "16vh",
                          cursor: idx === 0 ? "pointer" : null,
                        }
                  }
                >
                  <img
                    src={img_url}
                    alt="homeBanner"
                    style={{
                      filter: idx !== 0 ? "brightness(70%)" : null,
                    }}
                    // style={
                    //   isMobileSize
                    //     ? {
                    //         height: "225px",
                    //       }
                    //     : isTabSize
                    //     ? {
                    //         height: "500px",
                    //       }
                    //     : {}
                    // }
                  />
                </div>
                {idx !== 0 ? (
                  <div className="homeBannerContainer">
                    <ContainerText
                      props={
                        <div className="textBanner">
                          <div>
                            <p className="textBannerMain">
                              Inspire Excellence and Make a Difference
                            </p>
                          </div>
                          <div data-aos="fade-left" data-aos-once>
                            <p className="textBannerSub">
                              Indonesia's leading in Sustainability Education
                              and Consultancy
                            </p>
                          </div>
                          <div data-aos="fade-left" data-aos-once>
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={() => navigate(learn_more_url)}
                            >
                              Learn more
                            </button>
                          </div>
                        </div>
                      }
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </Slide>
      </div>
      {upcomingWorkshop.length > 0 ? (
        <div id="news">
          <div className="webTitle header">
            <p>UPCOMING WORKSHOP</p>
          </div>
          <ContainerText
            props={
              <div className="blogCardContainerWorkshop">
                {upcomingWorkshop.map((workshop) => {
                  return (
                    <div className="blogCardEachWorkshop" key={workshop.id}>
                      <div className="workshopCardPicture">
                        <img
                          src={ISA_academy_detail_background}
                          alt="workshop background"
                          className="academyDetailImageBackground"
                        />
                        <img
                          src={workshop.coverImage}
                          onClick={() =>
                            navigate(`/Academy/details/${workshop.id}`)
                          }
                          alt="academy"
                          className="workshopCardPictureMainImage"
                        />
                      </div>
                      <div
                        className="blogCardTitle title"
                        onClick={() =>
                          navigate(`/Academy/details/${workshop.id}`)
                        }
                      >
                        <p>{capitalizeFirstLetter(workshop.workshopTitle)}</p>
                      </div>
                      <div className="blogCardDate">
                        <img src={DateIcon} alt="academy"></img>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            className="paragraf"
                            style={{
                              marginLeft: "0",
                              marginRight: "1em",
                              fontWeight: "bold",
                            }}
                          >
                            {moment(workshop.scheduleObj.start_date).format(
                              "dddd, MMMM Do YYYY"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="blogCardMethod">
                        <img src={Method} alt="academy"></img>
                        <div className="paragraf">
                          {workshop.scheduleObj.method_of_learning}
                        </div>
                      </div>
                      <div className="blogCardLocation">
                        <img src={Location} alt="academy"></img>
                        <div className="paragraf">
                          {capitalizeFirstLetter(workshop.location)}
                        </div>
                      </div>
                      <div className="ISAcompanyButton">
                        <button
                          type="button"
                          className="btn btn-warning paragrafDesc"
                          onClick={() =>
                            navigate(`/Academy/details/${workshop.id}`)
                          }
                        >
                          View details
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            }
          />
        </div>
      ) : null}
      {/* Infographic about ESG and Sustainability */}
      <div style={{ marginTop: isMobileSize ? "2em" : "7em" }}>
        <ContainerText
          props={
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  "/Diploma-PM-for-ESG/download-file-form/infographic-esg-sustainability"
                )
              }
            >
              <img
                src={landing_page_infographic_banner}
                alt="infographics banner"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </div>
          }
        />
      </div>
      {/* Vision and Goal */}
      <div id="visionAndGoal">
        <ContainerText
          props={
            <div>
              <div>
                <p className="visionAndGoalText headerDescription">
                  <span className="visionAndGoalTextMain">Our Vision</span> is
                  to Inspire, Educate, and Collaborate with Individuals and
                  Organizations in Achieving Sustainability
                </p>
              </div>
              <div>
                <p className="visionAndGoalText headerDescription">
                  <span className="visionAndGoalTextMain">Our Goal</span> is to
                  be a credible Think-Tank for Leadership, Education and
                  Partnership in Sustainability for the World
                </p>
              </div>
            </div>
          }
        />
      </div>
      {/* Side Banner */}
      <div id="sideBanner">
        <div className="sideBannerLeft">
          <img src={SideBanner1} alt="" />
          <ContainerText
            marginLeft={0}
            props={
              <div className="sideBannerLeftContainer">
                <div className="sideBannerLeftText">
                  <p className="sideBannerTextMain headerDescription">
                    Creating Meaningful Change for Sustainable Future
                  </p>
                  <p className="sideBannerTextSub paragraf">
                    Our planet’s condition is rapidly changing due to climate
                    change, and we know that it becomes more drastic with each
                    passing moment. For the future of our world, we realize that
                    major changes need to be made to keep our planet livable,
                    but many of those changes can only be brought on by those
                    with the capacity to do so. Due to this, businesses now play
                    a vital role in our current society.
                  </p>
                  <a className="sideBannerTextLink d-none" href="#">
                    Read full mission statement{" "}
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            }
          />
        </div>
        <div className="sideBannerRight">
          <img src={SideBanner2} alt="" />
          <ContainerText
            marginRight={0}
            props={
              <div className="sideBannerRightContainer">
                <div className="sideBannerRightText">
                  <p className="sideBannerTextMain headerDescription">
                    We are committed to your success
                  </p>
                  <p className="sideBannerTextSub paragraf">
                    At ISA, giving our best is not just an option, it is
                    something that we know we must do. We don’t want to simply
                    give businesses their basic needs in sustainability, but we
                    also want to go beyond it. Ensuring the satisfaction of
                    those that collaborate with us is a task that we constantly
                    keep in mind.
                  </p>
                  <a className="sideBannerTextLink d-none" href="#">
                    Learn more <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            }
          />
        </div>
      </div>
      {/* Programs */}
      <div id="programs">
        <div className="webTitle header">
          <p>Let Us Help you to Achieve Sustainability</p>
        </div>
        <ContainerText
          props={
            <div className="programsCard">
              <div
                className="programsEachCard"
                onClick={() => navigate(`/Expertise/ESGroadMap`)}
              >
                <img src={CardProgram1} alt="" />
                <p className="paragraf">
                  ESG Roadmap, Policies, Target and Metrics
                </p>
              </div>
              <div
                className="programsEachCard"
                onClick={() => navigate(`/Expertise/ImpactAnalysis`)}
              >
                <img src={CardProgram2} alt="" />
                <p className="paragraf">Impact Analysis and Reporting</p>
              </div>
              <div
                className="programsEachCard"
                onClick={() => navigate(`/Expertise/SustainabilityReport`)}
              >
                <img src={CardProgram3} alt="" />
                <p className="paragraf">Sustainability Report</p>
              </div>
              <div
                className="programsEachCard"
                onClick={() => navigate(`/Expertise/VocationalStudies`)}
              >
                <img src={CardProgram4} alt="" />
                <p className="paragraf">
                  Vocational Studies
                  <br />
                  <span className="programsEachCardSmallText">
                    Collaboration with Australia-based Polytechnic
                  </span>
                </p>
              </div>
            </div>
          }
        />
      </div>
      {/* Events */}
      <div id="events" style={{ display: "none" }}>
        <div className="webTitle">
          <p>Join Our Events</p>
        </div>
        <div className="eventsPagination">
          <ContainerText props={<PaginatedItems itemsPerPage={3} />} />
        </div>
      </div>
      {/* Small Banner */}
      <div id="mentorship" style={{ display: "none" }}>
        <ContainerText
          props={
            <div className="mentorshipContainer">
              <div className="mentorshipCollaboration">
                <p>In collaboration with:</p>
                <img src={CCSlogo} alt="CCS" />
              </div>
              <div className="mentorshipMain">
                <div className="mentorshipMainText">
                  <p>Get FREE 1-On-1 Mentorship Session</p>
                </div>
                <div className="mentorshipMainButton">
                  <button type="button" className="btn">
                    Join now
                  </button>
                </div>
              </div>
              <div className="mentorshipSubtext">
                <p>Let’s solve your challenges together</p>
              </div>
            </div>
          }
        />
      </div>
      {/* News Updates */}
      {news.length > 0 ? (
        <div id="news">
          <div className="webTitle header">
            <p>Read the Latest Updates</p>
          </div>
          <ContainerText
            props={
              <div className="newsCards">
                {news.map((element) => {
                  return element.status === "Published" ? (
                    <div
                      className="newsEachCard"
                      key={element.id}
                      onClick={() => navigate(`/Blog/${element.id}`)}
                    >
                      <img src={element.picture} alt="newsCard" />
                      <div className="newsEachCardText">
                        <p>
                          <div className="title">{element.title}</div>
                          <div className="newsEachCardTextSub">
                            <span className="paragraf">
                              {element.description}
                            </span>
                            <div className="newsArrow">
                              <i className="fas fa-arrow-right newsSmallArrow"></i>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            }
          />
          <div className="buttonViewAll paragrafDesc">
            <a
              style={{ border: "1px solid black" }}
              href="#blog"
              onClick={() => navigate(`/Blog`)}
            >
              View all
            </a>
          </div>
        </div>
      ) : null}
      {/* Clients */}
      <div id="clients">
        <div className="webTitle header">
          <p>Our Clients</p>
        </div>
        <ContainerText
          props={
            <div className="clientsLogo">
              {clients.map((client) => (
                <div className="clientsLogoContainer" key={client.id}>
                  <img src={client.clientImage} alt={client.clientName} />
                </div>
              ))}
            </div>
          }
        />
        <div className="buttonViewAll paragrafDesc">
          <a
            style={{ border: "1px solid black" }}
            href="#clientsPage"
            onClick={() => navigate(`/Clients`)}
          >
            View more
          </a>
        </div>
      </div>
      {/* Videos */}
      <div id="videos">
        <LearningVideosContent />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
