import { useEffect, useState } from "react";

// React Navigation
import { useNavigate } from "react-router-dom";

// Icons and Backgrounds
import BlogBanner from "../../images/background/company.png";
import SortBlack from "../../images/icons/SortBlack.png";
import NotFound from "../../images/icons/NotFound.png";
import NoWorkshopAvailable from "../../images/icons/NoWorkshopAvailable.png";

// Axios
import Axios from "../../api/axios";

// CSS Styling
import "../../styles/blog.css";

// Components
import BlogButton from "../../components/BlogButton";
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LearningVideosContent from "../../components/LearningVideosContent";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// API
import { getCategories } from "../../api/getCategories";
import { getUpdatedNews } from "../../api/getUpdatedNews";
import { getOldestNews } from "../../api/getOldestNews";

// React Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../lotties/paperplane.json";

const Blog = () => {
  const [news, setNews] = useState([]);
  const [isBrowseButton, setBrowseButton] = useState(false);
  const [checkedContent, setCheckedContent] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [categories, setCategories] = useState([]);
  const [buttonHandler, setButtonHandler] = useState(true);
  const [isOldest, setOldest] = useState(false);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  // React slick
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
  };

  // React lotties
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Open button for Sort/Browse function
  const openButton = (className) => {
    document
      .getElementsByClassName(className)[0]
      .scrollIntoView({ block: "center" });
    document.body.style.overflowY = "hidden";
    document.getElementsByClassName("overlayBlogButton")[0].style.visibility =
      "unset";
    document.getElementsByClassName("overlayBlogButton")[0].style.opacity = 1;
    document.getElementsByClassName(className)[0].style.visibility = "unset";
    document.getElementById("navigationBar").style.display = "none";

    if (className === "ButtonListBrowse") {
      setBrowseButton(true);
    }
  };

  // Close button for Sort/Browse function
  const closeButton = (className) => {
    document.body.style.overflowY = "unset";
    document.getElementsByClassName("overlayBlogButton")[0].style.visibility =
      "hidden";
    document.getElementsByClassName("overlayBlogButton")[0].style.opacity = 0;
    document.getElementsByClassName(className)[0].style.visibility = "hidden";
    document.getElementById("navigationBar").style.display = "block";

    if (className === "ButtonListBrowse") {
      setBrowseButton(false);
    }
  };

  // Click to view all news
  const viewAllNews = async () => {
    const response = await Axios.get("/news", {
      params: {
        contentSelected: checkedContent,
      },
    }).catch((err) => {
      console.log("Err: ", err);
    });
    setNews(response.data);
    setButtonHandler(false);
  };

  // Fetching updated news with query
  const fetchUpdatedNews = async () => {
    setLoading(true);
    try {
      const response = await getUpdatedNews(checkedContent);
      setNews(response.data);
      setButtonHandler(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetching outdated news with query
  const fetchOldestNews = async () => {
    setLoading(true);
    try {
      const response = await getOldestNews(checkedContent);
      setNews(response.data);
      setButtonHandler(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // Conditional checkbox on button function
  const checkSelectedContent = (contentType) => {
    if (contentType === "Select All") {
      if (!selectAll) {
        setSelectAll(!selectAll);
        setCheckedContent(categories.map((category) => category.name));
      } else {
        setSelectAll(!selectAll);
        setCheckedContent([]);
      }
    } else {
      if (checkedContent.indexOf(contentType) < 0) {
        setCheckedContent([...checkedContent, contentType]); // push element
      } else {
        setCheckedContent(
          checkedContent.filter((content) => content !== contentType)
        ); // remove element
        setSelectAll(false);
      }
    }
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    setNews([]);
    if (isOldest) {
      fetchOldestNews();
    } else {
      fetchUpdatedNews();
    }
  }, [checkedContent, isOldest]);

  useEffect(() => {
    fetchUpdatedNews();
  }, [checkedContent]);

  useEffect(() => {
    getCategories()
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Header />
      <div id="blog">
        <div className="blogContainer">
          <div className="blogBannerContainer">
            <img src={BlogBanner} alt="" />
            <ContainerText
              props={
                <div className="blogBannerText">
                  <div className="blogBannerTitle header">
                    <p>Read the Latest Updates</p>
                  </div>
                  <div className="blogBannerSubtitle title">
                    <p>
                      Explore the latest insights, stories and news related to
                      Sustainability at ISA
                    </p>
                  </div>
                </div>
              }
            />
          </div>
          <ContainerText
            props={
              <div id="blogNewsButton" className="blogFunctionButton">
                <div className="blogFunctionButtonContainer">
                  <div className="browseButton">
                    <BlogButton
                      onClickButton={() => openButton("ButtonListBrowse")}
                      text="Browse content type"
                    />
                    <div
                      className="overlayBlogButton"
                      onClick={() =>
                        isBrowseButton
                          ? closeButton("ButtonListBrowse")
                          : closeButton("ButtonListSort")
                      }
                    ></div>
                    <div className="ButtonListBrowse">
                      <div className="ButtonListContainer">
                        <div className="ButtonListTitle">
                          <p>Browse content type</p>
                          <div
                            onClick={() =>
                              isBrowseButton
                                ? closeButton("ButtonListBrowse")
                                : closeButton("ButtonListSort")
                            }
                            style={{
                              textAlign: "right",
                              flexGrow: 1,
                              cursor: "pointer",
                            }}
                          >
                            <p>Save</p>
                          </div>
                        </div>
                        <hr />
                        <div className="ButtonFunction">
                          <div className="ButtonChildrenList">
                            <input
                              onChange={() =>
                                checkSelectedContent("Select All")
                              }
                              checked={selectAll}
                              type="checkbox"
                            />
                            <span> Select All</span>
                            <br />
                          </div>
                          {categories.map((category) => {
                            return category.name !== "Uncategorized" ? (
                              <div
                                className="ButtonChildrenList"
                                key={category.id}
                              >
                                <input
                                  onChange={() =>
                                    checkSelectedContent(category.name)
                                  }
                                  checked={
                                    checkedContent.indexOf(category.name) > -1
                                  }
                                  type="checkbox"
                                />
                                <span> {category.name}</span>
                                <br />
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sortButton">
                    <BlogButton
                      onClickButton={() => openButton("ButtonListSort")}
                      icon={SortBlack}
                      iconDesc="Sort Button"
                      iconWidth="1em"
                      text="Sort by"
                    />
                    <div className="ButtonListSort">
                      <div className="ButtonListContainer">
                        <div className="ButtonListTitle">
                          <p>
                            <img src={SortBlack} alt="Sort Button" />
                            Sort by
                          </p>
                          <div
                            onClick={() =>
                              isBrowseButton
                                ? closeButton("ButtonListBrowse")
                                : closeButton("ButtonListSort")
                            }
                            style={{
                              textAlign: "right",
                              flexGrow: 1,
                              cursor: "pointer",
                            }}
                          >
                            <p>Save</p>
                          </div>
                          <hr />
                        </div>
                        <div className="ButtonFunction">
                          <div
                            className="ButtonChildrenList"
                            onClick={() => setOldest(false)}
                          >
                            <p>Newest</p>
                          </div>
                          <div
                            className="ButtonChildrenList"
                            onClick={() => setOldest(true)}
                          >
                            <p>Oldest</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
          <ContainerText
            props={
              <div className="bigScreen">
                <div className="blogCardContainer">
                  {news.length > 0 ? (
                    news.map((element) => {
                      return element.status === "Published" ? (
                        <div className="blogCardEach" key={element.id}>
                          <div className="blogCardPicture">
                            <img
                              src={element.picture}
                              onClick={() => navigate(`/Blog/${element.id}`)}
                              alt="Blog"
                            />
                          </div>
                          <div
                            className="blogCardTitle title"
                            onClick={() => navigate(`/Blog/${element.id}`)}
                          >
                            <p>{element.title}</p>
                          </div>
                          <div className="blogCardDate paragrafDesc">
                            <p>
                              <i>
                                {element.date} - {element.contentType}
                              </i>
                            </p>
                          </div>
                          <div className="blogCardSubtitle paragraf">
                            <p>{element.description}</p>
                          </div>
                        </div>
                      ) : null;
                    })
                  ) : isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Lottie
                        options={lottieOptions}
                        height={200}
                        width={400}
                      />
                      <h4
                        style={{
                          color: "#0D4C86",
                        }}
                      >
                        LOADING
                      </h4>
                      <p
                        style={{
                          color: "#0D4C86",
                        }}
                      >
                        Processing your request, please wait...
                      </p>
                    </div>
                  ) : !isLoading &&
                    checkedContent.length <= 0 &&
                    !selectAll &&
                    !isOldest ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ marginBottom: "3em" }}>
                        <img
                          src={NoWorkshopAvailable}
                          alt="Data not found icon"
                          width={200}
                        />
                      </div>
                      <h4
                        style={{
                          color: "#0D4C86",
                        }}
                      >
                        Blog not found
                      </h4>
                      <p
                        style={{
                          color: "#0D4C86",
                        }}
                      >
                        Please check again another time...
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ marginBottom: "3em" }}>
                        <img
                          src={NotFound}
                          alt="Data not found icon"
                          width={200}
                        />
                      </div>
                      <h4
                        style={{
                          color: "#0D4C86",
                        }}
                      >
                        No results found
                      </h4>
                      <p
                        style={{
                          color: "#0D4C86",
                        }}
                      >
                        Please check again another time...
                      </p>
                    </div>
                  )}
                </div>
              </div>
            }
          />
          <ContainerText
            props={
              <div className="smallScreen">
                {/* <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={3}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  style={{ display: "none" }}
                >
                  {news.map((element, index) => {
                    return element.status === "Published" ? (
                      <> </>
                    ) : // <div key={element.id}>{index}</div>
                    null;
                  })}
                </Slider>
                <Slider
                  {...settings}
                  asNavFor={nav2}
                  ref={(slider1) => setNav1(slider1)}
                  style={{
                    textAlign: "left",
                    marginRight: "3em",
                    marginTop: "1em",
                  }}
                >
                  {news.map((element, idx) => {
                    return element && element.status === "Published" ? (
                      <div className="blogCardEach" key={idx}>
                        <div className="blogCardPicture">
                          <img
                            src={element.picture}
                            onClick={() => navigate(`/Blog/${element.id}`)}
                            alt="Blog"
                          />
                        </div>
                        <div
                          className="blogCardTitle"
                          onClick={() => navigate(`/Blog/${element.id}`)}
                        >
                          <p>{element.title}</p>
                        </div>
                        <div className="blogCardDate">
                          <p>
                            <i>
                              {element.date} - {element.contentType}
                            </i>
                          </p>
                        </div>
                        <div className="blogCardSubtitle">
                          <p>{element.description}</p>
                        </div>
                      </div>
                    ) : null;
                  })}
                </Slider> */}
                {news && Array.isArray(news) && news.length > 0 ? (
                  news.map((element, idx) => {
                    return element && element.status === "Published" ? (
                      <div className="blogCardEach" key={idx}>
                        <div className="blogCardPicture">
                          <img
                            src={element.picture}
                            onClick={() => navigate(`/Blog/${element.id}`)}
                            alt="Blog"
                          />
                        </div>
                        <div
                          className="blogCardTitle"
                          onClick={() => navigate(`/Blog/${element.id}`)}
                        >
                          <p>{element.title}</p>
                        </div>
                        <div
                          className="blogCardDate"
                          style={{ marginTop: "0" }}
                        >
                          <p>
                            <i>
                              {element.date} - {element.contentType}
                            </i>
                          </p>
                        </div>
                        <div className="blogCardSubtitle">
                          <p>{element.description}</p>
                        </div>
                      </div>
                    ) : null;
                  })
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      marginTop: "4em",
                    }}
                  >
                    <div style={{ marginBottom: "3em" }}>
                      <img
                        src={NotFound}
                        alt="Data not found icon"
                        width={200}
                      />
                    </div>
                    <h4
                      style={{
                        color: "#0D4C86",
                      }}
                    >
                      No results found
                    </h4>
                    <p
                      style={{
                        color: "#0D4C86",
                      }}
                    >
                      Please check again another time...
                    </p>
                  </div>
                )}
              </div>
            }
          />
          {buttonHandler && news.length >= 6 ? (
            <div className="buttonViewAll bigScreen">
              <a
                style={{ border: "1px solid black" }}
                href="#blogNewsButton"
                onClick={viewAllNews}
              >
                View all
              </a>
            </div>
          ) : null}
        </div>

        {/* Videos */}
        <div id="videos">
          <LearningVideosContent />
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Blog;
