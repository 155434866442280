import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS styling
import "../../styles/activitiesPublic.css";
import "../../styles/clientBook.css";

// moment js
import moment from "moment";

// HTML react parser
import parse from "html-react-parser";

// lucide-react
import { Eye } from "lucide-react";

// image dummy
import NoWorkshopAvailable from "../../images/icons/NoWorkshopAvailable.png";
import EN_flag_icon from "../../images/icons/countryFlags/EN_flag_icon.png";
import ID_flag_icon from "../../images/icons/countryFlags/ID_flag_icon.png";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import ContainerText from "../../components/Container.js";

// apis
import { fetchBooks } from "../../api/books.js";

const Books = () => {
  const navigate = useNavigate();

  const [books, setBooks] = useState([]);

  const handleFetchBooks = async () => {
    const result = await fetchBooks();
    if (result && result.data) {
      let finalBooks = result.data;
      finalBooks =
        finalBooks && Array.isArray(finalBooks) && finalBooks.length > 0
          ? finalBooks.sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
          : [];
      setBooks(finalBooks);
    }
  };

  useEffect(() => {
    handleFetchBooks();
  }, []);

  return (
    <div>
      <Header />
      <div className="activityPublicContainer">
        <ContainerText
          props={
            <div
              className="activityPublicDetailCardContainerDesc"
              style={{ margin: "25px" }}
            >
              <div style={{ marginTop: "5em", marginBottom: "4em" }}>
                <div
                  style={{
                    color: "#FF9F1E",
                    fontWeight: "700",
                  }}
                  className="header"
                >
                  Books
                </div>
              </div>
              {books && Array.isArray(books) && books.length > 0 ? (
                books.map((book) => {
                  const {
                    id,
                    img_url,
                    title,
                    description,
                    published_on,
                    language,
                    number_of_pages,
                  } = book || {};

                  return (
                    <div
                      key={id}
                      className="eachClientBook"
                      onClick={() => navigate(`/Books/${id}`)}
                    >
                      <div className="eachClientBookImageContainer">
                        <img src={img_url} alt={`book-${title}`} />
                        <div className="eachClientBookImageContainerOverlay">
                          <div className="eachClientBookImageContainerOverlayContent">
                            <Eye />
                            <div>View Author</div>
                          </div>
                        </div>
                      </div>
                      <div className="eachClientBookTitleDescContainer">
                        <div className="eachClientBookTitleStyle title">
                          {title}
                        </div>
                        <div className="eachClientBookPublishedDateStyle paragrafDesc">
                          {`Published on: ${
                            published_on
                              ? moment(published_on).format("DD/MM/YYYY")
                              : "-"
                          }`}
                        </div>
                        <div className="eachClientBookPublishedDateStyle paragrafDesc">
                          <div>Language:</div>
                          <div>{`${language} ${
                            language && language === "English" ? `(EN)` : `(ID)`
                          }`}</div>
                          <img
                            src={
                              language === "English"
                                ? EN_flag_icon
                                : ID_flag_icon
                            }
                            alt="country flag"
                          />
                        </div>
                        <div className="eachClientBookPublishedDateStyle paragrafDesc">
                          {`Pages: ${number_of_pages}`}
                        </div>
                        <div className="eachClientBookDescriptionStyle paragraf">
                          {parse(description)}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingBottom: "3em",
                  }}
                >
                  <div style={{ marginBottom: "3em" }}>
                    <img
                      src={NoWorkshopAvailable}
                      alt="Data not found icon"
                      width={200}
                    />
                  </div>
                  <h4
                    style={{
                      color: "#000000",
                    }}
                  >
                    No book available at this moment. Stay tune!
                  </h4>
                </div>
              )}
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default Books;
