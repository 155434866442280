import React from "react";

// CSS Styling
import "../../../styles/admin.css";
import "../../../styles/isaAcademy.css";

// Icons
import NotepadPencil from "../../../images/icons/NotepadPencil.png";
import DeleteIcon from "../../../images/icons/DeleteIcon.png";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../../lotties/paperplane.json";

// Axios and API
import { deleteWorkshopTestimonial } from "../../../api/deleteWorkshopTestimonial";

// Moment.js
import moment from "moment";

// Sweet Alert v2
import Swal from "sweetalert2";

// Helpers
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";

const TestimonialList = (props) => {
  const {
    navigate,
    isLoading,
    setLoading,
    testimonials,
    fetchAllTestimonials,
  } = props;

  const handleDeleteTestimonial = async (testimonialId) => {
    Swal.fire({
      customClass: {
        cancelButton: "cancelPostButton",
        confirmButton: "confirmPostButton",
        title: "cancelPostButtonTitle",
      },
      title: "are you sure you want to delete this? ",
      text: "Once confirmed, you can’t undo this.",
      padding: "3em",
      showCancelButton: true,
      confirmButtonColor: "white",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    })
      .then((res) => {
        if (res.isConfirmed) {
          setLoading(true);
          deleteWorkshopTestimonial(testimonialId)
            .then((res) => {
              Swal.fire({
                customClass: {
                  confirmButton: "publishButton",
                  title: "publishTitle",
                  popup: "publishPopup",
                },
                showCloseButton: true,
                confirmButtonColor: "#1F81DC",
                title: "Success!",
                text: "You have successfully deleted testimonial",
                confirmButtonText: "Okay, got it!",
              });
              fetchAllTestimonials();
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <div className="adminPostList">
        <div className="adminPostListTitleContainer">
          <div className="adminPostListTitle">
            <p>List of Testimonial</p>
          </div>
          <div className="adminPostListFunction">
            {/* filter button goes here */}
            <div className="adminPostListEachFunction addPostButton">
              <button
                onClick={() => navigate("/admin/isaAcademy-testimonial/add")}
              >
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="adminPostTable">
        <div className="adminPostTableContainer">
          <table>
            <thead>
              <tr>
                <th className="thLastModified">Last modified</th>
                <th className="thName">Name</th>
                <th>Posted by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading && testimonials.length > 0 ? (
                testimonials.map((testimonial) => (
                  <tr key={testimonial.id} className="adminTableRow">
                    <td className="tdLastModified">
                      <div style={{ marginRight: "1em" }}>
                        {moment(testimonial.lastModified).format("DD/MM/YYYY")}
                      </div>
                      <div>
                        {moment(testimonial.lastModified).format("HH:mm")}
                      </div>
                    </td>
                    <td>{capitalizeFirstLetter(testimonial.name)}</td>
                    <td>{testimonial.postedBy}</td>
                    <td className="tdEdit">
                      <div
                        className="editWorkshopBtn"
                        onClick={() =>
                          navigate(
                            `/admin/isaAcademy-testimonial/edit/${testimonial.id}`
                          )
                        }
                      >
                        Edit
                      </div>
                      <div>
                        <img
                          src={DeleteIcon}
                          alt="Delete Icon"
                          className="deleteWorkshopBtn"
                          onClick={() =>
                            handleDeleteTestimonial(testimonial.id)
                          }
                        ></img>
                      </div>
                    </td>
                  </tr>
                ))
              ) : isLoading ? (
                <div
                  style={{
                    width: "75%",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "5em",
                  }}
                >
                  <Lottie options={lottieOptions} height={200} width={400} />
                  <h4
                    style={{
                      color: "#0D4C86",
                    }}
                  >
                    LOADING
                  </h4>
                  <p
                    style={{
                      color: "#0D4C86",
                    }}
                  >
                    Processing your request, please wait...
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    width: "75%",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "5em",
                  }}
                >
                  <div>
                    <img
                      src={NotepadPencil}
                      alt="Data not found icon"
                      width={350}
                    />
                  </div>
                  <h4
                    style={{
                      color: "#0D4C86",
                    }}
                  >
                    You have not written any post yet
                  </h4>
                  <p
                    style={{
                      color: "#0D4C86",
                    }}
                  >
                    Add new post to start..
                  </p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TestimonialList;
