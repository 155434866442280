import React, { useEffect, useState } from "react";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../lotties/paperplane.json";

// Axios and API
import { addTag } from "../../api/addTag";
import { fetchTags } from "../../api/fetchTags";
import {
  addFounderStory,
  editFounderStory,
  fetchFounderStoryDetail,
} from "../../api/founderStories";

// React Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Moment.js
import moment from "moment";

// Icons
import uploadImageBtn from "../../images/icons/admin/uploadImageBtn.png";
import addMoreIcon from "../../images/icons/admin/addMoreIcon.png";
import iconChangeImage from "../../images/icons/admin/changeImageIcon.png";
import DeleteIcon from "../../images/icons/DeleteIcon.png";

// Sweet Alert v2
import Swal from "sweetalert2";

// Components
import Loader from "../../components/Loader";
import WorkshopDropdownSelect from "../ISA-Academy-Form/components/WorkshopDropdownSelect";
import ArrowDownIcon from "../../images/icons/admin/arrowDownIcon";
import InputUploadImages from "../../components/InputUploadImages";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const FoundersStoryForm = (props) => {
  const {
    navigate,
    setShowAlertWorkshop,
    setWorkshopAlertMsg,
    editId,
    fetchAllFoundersStories,
  } = props;
  const forceUpdate = useForceUpdate();
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [founderStoryDate, setFounderStoryDate] = useState(null);
  const [selectedFounderStoryDate, setSelectedFounderStoryDate] =
    useState(null);
  const [founderStoryTitle, setFounderStoryTitle] = useState("");
  const [founderStoryLink, setFounderLink] = useState("");
  const [founderStoryDescription, setFounderStoryDescription] = useState("");
  const [founderStoryInputSummary, setFounderStoryInputSummary] = useState("");

  const [images, setImages] = useState([]);
  const [imageCounter, setImageCounter] = useState([0]);

  const [founderStoryDateEmpty, setFounderStoryDateEmpty] = useState(null);
  const [founderStoryTitleEmpty, setFounderStoryTitleEmpty] = useState(false);
  const [founderStoryDescriptionEmpty, setFounderStoryDescriptionEmpty] =
    useState(false);
  const [founderStoryInputSummaryEmpty, setFounderStoryInputSummaryEmpty] =
    useState(false);
  const [imageEmpty, setImageEmpty] = useState(false);
  const [tagEmpty, setTagEmpty] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);

  useEffect(() => {
    fetchAllTags(); // eslint-disable-next-line
    if (editId) {
      fetchOneFoundersStory(editId);
    }
  }, [editId]);

  const fetchOneFoundersStory = async (foundersStoryId) => {
    setIsLoadingSave(true);
    const response = await fetchFounderStoryDetail(foundersStoryId);
    if (response && response.status === 200 && response.data) {
      const {
        FounderStoryImages = [],
        FounderStoryTags = [],
        story_date,
        input_summary = "",
        description = "",
        link = "",
        title = "",
      } = response.data;
      setFounderStoryDate(moment(story_date).format("DD/MM/YYYY"));
      setSelectedFounderStoryDate(new Date(story_date));
      setFounderStoryTitle(title);
      setFounderLink(link);
      setFounderStoryInputSummary(input_summary);
      setFounderStoryDescription(description);

      let finalImageCounter = [];

      const finalImages =
        FounderStoryImages &&
        Array.isArray(FounderStoryImages) &&
        FounderStoryImages.length > 0 &&
        FounderStoryImages.map((foundersStoryImage, index) => {
          finalImageCounter.push(index);
          const { img_url, id } = foundersStoryImage || {};
          const objImage = {
            image: img_url,
            founderStoryImageId: id,
          };
          return objImage;
        });
      setImages(finalImages);
      setImageCounter(finalImageCounter);

      const finalTags =
        FounderStoryTags &&
        Array.isArray(FounderStoryTags) &&
        FounderStoryTags.length > 0 &&
        FounderStoryTags.map((foundersStoryTag) => {
          const { Tag: { id, tag_name } = {} } = foundersStoryTag || {};
          const objTag = { id, label: tag_name, value: tag_name };
          return objTag;
        });
      setTags(finalTags);
    }
    setIsLoadingSave(false);
  };

  const fetchAllTags = async () => {
    setIsLoadingSave(true);
    const response = await fetchTags();
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      const finalTags = [];
      data.forEach((el) => {
        const { tag_name, id } = el || {};
        finalTags.push({ id, label: tag_name, value: tag_name });
      });
      setTagOptions(finalTags);
      setIsLoadingSave(false);
    }
    forceUpdate();
  };

  const onDropdownChange = (val, key, flagger) => {
    if (flagger) {
      const isSameProperty = (val, tagOptions) =>
        val.value === tagOptions.value;

      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          (leftValue) =>
            !right.some((rightValue) => compareFunction(leftValue, rightValue))
        );

      const onlyInVal = onlyInLeft(val, tagOptions, isSameProperty);
      const onlyInTagOptions = onlyInLeft(tagOptions, val, isSameProperty);

      const result = [...onlyInVal, ...onlyInTagOptions];

      let arrTagOptions = tagOptions;
      result.forEach(async (el) => {
        if (el["__isNew__"]) {
          const { label, value } = el || {};
          const createdTag = await addTag({ tag_name: value });
          if (createdTag && createdTag.status === 201 && createdTag.data) {
            arrTagOptions.push({ label, value, id: createdTag.data.id });
            setTagOptions(arrTagOptions);
            setTags([...tags, { label, value, id: createdTag.data.id }]);
          }
        }
      });
    }
    key(val);
  };

  const handleChangeInputDate = (date) => {
    setSelectedFounderStoryDate(date);
    setFounderStoryDate(moment(date).format("DD/MM/YYYY"));
  };

  const onInputChange = (str, setter) => {
    setter(str);
  };

  const addMoreImageInput = () => {
    const lastIndexImageCounter = imageCounter[imageCounter.length - 1];
    if (images.length === 0) {
      setShowAlertWorkshop(true);
      setWorkshopAlertMsg(
        "Alert! Please upload image first to be able to add more field"
      );
    } else {
      if (!images[lastIndexImageCounter]) {
        setShowAlertWorkshop(true);
        setWorkshopAlertMsg(
          "Alert! Please upload image first to be able to add more field"
        );
      } else {
        const lastIndexFacilitator = images[lastIndexImageCounter];
        if (!lastIndexFacilitator["image"]) {
          setShowAlertWorkshop(true);
          setWorkshopAlertMsg(
            "Alert! Please upload image first to be able to add more field"
          );
        } else {
          setShowAlertWorkshop(false);
          setWorkshopAlertMsg("");
          const tempImageCounter = imageCounter;
          const currentCounter = imageCounter.length;
          if (currentCounter < 20) {
            tempImageCounter.push(currentCounter);
            setImageCounter(tempImageCounter);
            forceUpdate();
          }
        }
      }
    }
  };

  const onImageChange = (files, index, key) => {
    if (files && files[0]) {
      const { size } = files[0] || {};
      let finalImages = images;
      let maxFileSize = 50 * 1024 * 1024; // make 50Mb max file size
      if (images.length === 0) {
        if (size >= maxFileSize) {
          finalImages.maxFileSize({
            [`${key}`]: URL.createObjectURL(files[0]),
            [`imageToSave`]: files[0],
            fileName: files[0].name,
            [`isFileSizeTooLargeMsg`]: "File too large (Max: 50Mb)",
          });
        } else {
          finalImages.push({
            [`${key}`]: URL.createObjectURL(files[0]),
            [`imageToSave`]: files[0],
            fileName: files[0].name,
            [`isFileSizeTooLargeMsg`]: "",
          });
        }
      } else {
        if (size >= maxFileSize) {
          finalImages[index] = {
            ...finalImages[index],
            [`imageToSave`]: files[0],
            fileName: files[0].name,
            [`${key}`]: URL.createObjectURL(files[0]),
            [`isFileSizeTooLargeMsg`]: "File too large (Max: 50Mb)",
          };
        } else {
          finalImages[index] = {
            ...finalImages[index],
            [`imageToSave`]: files[0],
            fileName: files[0].name,
            [`${key}`]: URL.createObjectURL(files[0]),
            [`isFileSizeTooLargeMsg`]: "",
          };
        }
      }
      setImages(finalImages);
      forceUpdate();
    }
  };

  const checkAllInputs = (e) => {
    e.preventDefault();
    setIsLoadingSave(true);

    let isFounderStoryDateEmpty = false;
    let isFounderStoryTitleEmpty = false;
    let isFounderStoryDescriptionEmpty = false;
    let isFounderStoryInputSummaryEmpty = false;
    let isTagEmpty = false;
    let isImageEmpty = false;
    let isLinkInvalid = false;
    // let maxLengthInputSummary = false;

    if (!founderStoryDate) {
      if (!editId) {
        if (!selectedFounderStoryDate) {
          isFounderStoryDateEmpty = true;
        }
      } else {
        isFounderStoryDateEmpty = true;
      }
    }
    if (!founderStoryTitle) {
      isFounderStoryTitleEmpty = true;
    }

    if (!founderStoryInputSummary) {
      isFounderStoryInputSummaryEmpty = true;
    }
    // else {
    //   if (founderStoryInputSummary.length > 255) {
    //     maxLengthInputSummary = true;
    //   } else {
    //     maxLengthInputSummary = false;
    //   }
    // }

    if (!founderStoryDescription) {
      isFounderStoryDescriptionEmpty = true;
    }

    if (tags.length <= 0) {
      isTagEmpty = true;
    }

    if (images.length <= 0) {
      isImageEmpty = true;
    } else {
      let finalImages = images;
      images.forEach((imageUploaded, index) => {
        const { image = "" } = imageUploaded || {};
        if (image) {
          finalImages[index]["emptyImage"] = false;
        } else {
          finalImages[index]["emptyImage"] = true;
          isImageEmpty = true;
        }
      });
      setImages(finalImages);
      forceUpdate();
    }

    if (founderStoryLink) {
      const websiteUrlRegex =
        /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
      if (websiteUrlRegex.test(founderStoryLink) !== true) {
        isLinkInvalid = true;
      }
    }

    setFounderStoryDateEmpty(isFounderStoryDateEmpty);
    setFounderStoryTitleEmpty(isFounderStoryTitleEmpty);
    setFounderStoryDescriptionEmpty(isFounderStoryDescriptionEmpty);
    setFounderStoryInputSummaryEmpty(isFounderStoryInputSummaryEmpty);
    setImageEmpty(isImageEmpty);
    setTagEmpty(isTagEmpty);
    setInvalidLink(isLinkInvalid);

    if (
      // maxLengthInputSummary !== true &&
      isFounderStoryDateEmpty !== true &&
      isFounderStoryTitleEmpty !== true &&
      isFounderStoryInputSummaryEmpty !== true &&
      isFounderStoryDescriptionEmpty !== true &&
      isImageEmpty !== true &&
      isTagEmpty !== true &&
      isLinkInvalid !== true
    ) {
      handleSave();
    } else {
      setIsLoadingSave(false);
      setShowAlertWorkshop(true);
      setWorkshopAlertMsg("Please check the error fields");
    }
  };

  const handleSave = async () => {
    const data = new FormData();

    let founder_story_date = moment(selectedFounderStoryDate, "YYYY-MM-DD");
    founder_story_date = new Date(founder_story_date).toISOString();

    const tagIds = [];
    tags.forEach((tag) => {
      tagIds.push(tag.id);
    });

    data.append("founder_story_title", founderStoryTitle);
    data.append("founder_story_description", founderStoryDescription);
    data.append("founder_story_input_summary", founderStoryInputSummary);
    if (founderStoryLink) {
      data.append("founder_story_link", founderStoryLink);
    }
    data.append("founder_story_date", founder_story_date);
    data.append("tagIds", JSON.stringify(tagIds));

    const founderStoryImageIds = [];
    images.forEach((uploadImage) => {
      const { imageToSave, founderStoryImageId } = uploadImage;
      if (imageToSave) {
        data.append("founder_story_images", imageToSave);
      } else {
        if (founderStoryImageId) {
          founderStoryImageIds.push(founderStoryImageId);
        }
      }
    });
    data.append("founderStoryImageIds", JSON.stringify(founderStoryImageIds));

    try {
      let result;
      if (editId) {
        result = await editFounderStory(editId, data);
      } else {
        result = await addFounderStory(data);
      }
      if (result) {
        Swal.fire({
          customClass: {
            confirmButton: "publishButton",
            title: "publishTitle",
            popup: "publishPopup",
          },
          showCloseButton: true,
          confirmButtonColor: "#1F81DC",
          title: "Success!",
          text: editId
            ? "This Founder's story has been succesfully edited"
            : "This Founder's story has been succesfully created",
          confirmButtonText: "Okay, got it!",
        });
        fetchAllFoundersStories();
        navigate(`/admin/founders-story`);
      }
    } catch (err) {
      const {
        founderStoryImg = "",
        index = "",
        errorName,
        errorMsg,
        message,
      } = err.response.data || {};

      if (
        message &&
        (message === "Unauthorized" ||
          message === "Unauthenticated" ||
          message === "TokenExpiredError")
      ) {
        Swal.fire({
          position: "top-end",
          text: "Token expired, please re-login",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
        setIsLoadingSave(false);
        localStorage.removeItem("isVerified");
        localStorage.removeItem("access_token");
        localStorage.removeItem("email");
        navigate("/signIn");
      } else if (errorName && errorName === "Bad request") {
      } else if (errorName && errorName === "File too large (Max: 5mb)") {
      } else {
        Swal.fire({
          position: "top-end",
          text: "Please fill all required elements",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
      }
    } finally {
      setIsLoadingSave(false);
    }
  };

  const handleDeleteImage = (imgUrl) => {
    let finalImages = images;
    let finalImageCounter = [];
    finalImages =
      finalImages &&
      Array.isArray(finalImages) &&
      finalImages.length > 0 &&
      finalImages.filter((img) => img.image !== imgUrl);
    finalImages.forEach((img, index) => {
      finalImageCounter.push(index);
    });
    if (finalImageCounter.length === 0) {
      finalImageCounter = [0];
    }
    setImageCounter(finalImageCounter);
    setImages(finalImages);
  };

  return (
    <div className="adminPostList">
      {isLoadingSave ? null : (
        <div className="adminPostListTitleContainer">
          <div className="adminPostListTitle">
            <p>{editId ? "Edit Founder's Story" : "Add New Founder's Story"}</p>
          </div>
        </div>
      )}
      {isLoadingSave ? (
        <div
          style={{
            width: "75%",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "5em",
          }}
        >
          <Lottie options={lottieOptions} height={200} width={400} />
          <h4
            style={{
              color: "#0D4C86",
            }}
          >
            LOADING
          </h4>
          <p
            style={{
              color: "#0D4C86",
            }}
          >
            Processing your request, please wait...
          </p>
        </div>
      ) : (
        <div className="adminPostAdd">
          <form>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Title</div>
              </div>
              <textarea
                required
                name="workshopTitle"
                type="text"
                className="xSmallInputBox"
                placeholder="Please enter title here..."
                value={founderStoryTitle}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setFounderStoryTitle)
                }
              ></textarea>
              {founderStoryTitleEmpty && !founderStoryTitle ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Input Summary</div>
                {/* <div className="secondLabel">
                  This description summary will appear on each image
                </div> */}
              </div>
              <textarea
                required
                name="founderStoryInputSummary"
                type="text"
                className="bigInputBox"
                placeholder="Please enter the summary here..."
                value={founderStoryInputSummary}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setFounderStoryInputSummary)
                }
              ></textarea>
              {founderStoryInputSummaryEmpty && !founderStoryInputSummary ? (
                <div className="errorMessage">
                  <p>This field is required.</p>
                </div>
              ) : null}
              {/* {founderStoryInputSummary.length > 255 ? (
                <div className="errorMessage">
                  <p>Please limit data to maximum of 255 characters.</p>
                </div>
              ) : null} */}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Description</div>
                {/* <div className="secondLabel">
                  This description summary will appear on each image
                </div> */}
              </div>
              <textarea
                required
                name="founderStoryDescription"
                type="text"
                className="bigInputBox"
                placeholder="Please enter the description here..."
                value={founderStoryDescription}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setFounderStoryDescription)
                }
              ></textarea>
              {founderStoryDescriptionEmpty && !founderStoryDescription ? (
                <div className="errorMessage">
                  <p>This field is required.</p>
                </div>
              ) : null}
            </div>
            {imageCounter &&
              Array.isArray(imageCounter) &&
              imageCounter.length > 0 &&
              imageCounter.map((counter) => {
                return (
                  <InputUploadImages
                    key={counter}
                    counter={counter}
                    firstInput={counter < 1 ? true : false}
                    images={images}
                    addMoreImageInput={addMoreImageInput}
                    addMoreIcon={addMoreIcon}
                    uploadImageBtn={uploadImageBtn}
                    iconChangeImage={iconChangeImage}
                    onImageChange={onImageChange}
                    imageEmpty={imageEmpty}
                    handleDeleteImage={handleDeleteImage}
                    deleteIcon={DeleteIcon}
                  />
                );
              })}
            <div className="typeOfWorkAndTagInput">
              <div className="eachContainer">
                <div className="eachLabel">Tags</div>
                <WorkshopDropdownSelect
                  placeholder="Please input tags here..."
                  options={tagOptions}
                  selectStyle={{
                    borderRadius: "40px",
                    fontSize: "15px",
                    padding: "5px",
                    borderColor: "#0D4C86",
                  }}
                  optionStyle={{
                    backgroundColor: "#0D4C86",
                    fontSize: "15px",
                    color: "#FFFFFF",
                  }}
                  createAble={true}
                  isMulti={true}
                  onChange={(e) => onDropdownChange(e, setTags, true)}
                  value={tags}
                />
                {tagEmpty && tags.length <= 0 ? (
                  <div className="errorMessage">
                    <p>This field is required.</p>
                  </div>
                ) : null}
              </div>
              <div className="eachContainer">
                <div className="eachLabel">Date</div>
                <DatePicker
                  selected={
                    selectedFounderStoryDate
                      ? selectedFounderStoryDate
                      : new Date()
                  }
                  onChange={(date) => handleChangeInputDate(date)}
                  customInput={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        border: "1px solid #0d4c86",
                        borderRadius: "40px",
                        height: "50px",
                        fontSize: "15px",
                        borderColor: "#0D4C86",
                        padding: "1em",
                      }}
                    >
                      <div>
                        {founderStoryDate
                          ? `${founderStoryDate}`
                          : "Select date"}
                      </div>
                      <ArrowDownIcon />
                    </div>
                  }
                  showMonthDropdown
                  showYearDropdown
                  todayButton="Today"
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                />
                {founderStoryDateEmpty && !founderStoryDate ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Link</div>
              </div>
              <textarea
                required
                name="founderStoryLink"
                type="text"
                className="xSmallInputBox"
                placeholder="Please input the supporting link here..."
                value={founderStoryLink}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setFounderLink)
                }
              ></textarea>
              {invalidLink ? (
                <div className="errorMessage">
                  <p>Please input the correct form of the website URL.</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostButtonContainer">
              <div>
                <span
                  className="postCancelButton btn"
                  onClick={() => navigate("/admin/founders-story")}
                >
                  Cancel
                </span>
              </div>
              <div>
                <button
                  className="postSaveButton btn"
                  onClick={(e) => checkAllInputs(e)}
                >
                  {isLoadingSave ? (
                    <span>
                      <Loader
                        type="spin"
                        color="black"
                        height="25px"
                        width="25px"
                      />
                    </span>
                  ) : (
                    <span>Save</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default FoundersStoryForm;
