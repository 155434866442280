import React from "react";

// Images & Icons
import PlayButton from "../images/icons/playButton.png";
import ContainerText from "./Container.js";

const LearningVideosContent = () => {
  return (
    <ContainerText
      props={
        <div className="videosContainer">
          <div className="webTitle header">
            <p>Watch our ISA Learning Videos</p>
          </div>
          <div className="videosSubtitle title">
            <p>
              Gain insights about Sustainability and what we can do for future
              generations
            </p>
          </div>
          <div className="videosCard">
            <div className="videosEachCard">
              <iframe
                className="videosResponsive"
                src="https://www.youtube.com/embed/jLozIXtrycQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="videosEachCard">
              <iframe
                className="videosResponsive"
                src="https://www.youtube.com/embed/5ZQqKrnX77Y"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="videosEachCard">
              <iframe
                className="videosResponsive"
                src="https://www.youtube.com/embed/guPAuU5oaWQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="videosEachCard">
              <iframe
                className="videosResponsive"
                src="https://www.youtube.com/embed/11N-U57i6Dg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="videosEachCard">
              <iframe
                className="videosResponsive"
                src="https://www.youtube.com/embed/ghB8QJVw2Ag"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="videosExploreMore paragrafDesc">
            <a
              style={{ border: "1px solid black" }}
              href="https://www.youtube.com/channel/UCWsQCHf9PodPl-_V_GaYorg/featured"
              target="_blank"
              rel="noreferrer"
            >
              Explore more <img src={PlayButton} alt="" />
            </a>
          </div>
        </div>
      }
    />
  );
};

export default LearningVideosContent;
