// Axios
import Axios from "./axios";

export const fetchWorkshops = (filter) => {
  const {
    sortById,
    sortByWorkshopTitle,
    // tags,
    typeOfWorkshop,
    methodOfLearning,
    displayOnHomePage,
  } = filter;

  return new Promise((resolve, reject) => {
    const data = Axios.get(`/academyworkshop`, {
      params: {
        sortById,
        sortByWorkshopTitle,
        // tags,
        typeOfWorkshop,
        methodOfLearning,
        displayOnHomePage,
      },
    });
    resolve(data);
  });
};
