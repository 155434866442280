import { useEffect, useState } from "react";

// react responsive
import { useMediaQuery } from "react-responsive";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// React Form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// Images and Icons
import PopupMessage from "../../images/icons/PopupMessage1.png";

// CSS styling
import "../../styles/contact.css";

// Helpers
import { openModal } from "../../helpers/openModal";
import { closeModal } from "../../helpers/closeModal";

// Sweetalert
import Swal from "sweetalert2";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DropdownSelect from "../../components/Dropdown";

// Api
import { sendNotificationRegistrationESGtoAdmin } from "../../api/sendNotificationRegistrationESGtoAdmin";

const RegistrationForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "all" });

  const isMobileSize = useMediaQuery({ query: "(max-width: 640px)" });
  const isTabSize = useMediaQuery({
    query: "(min-width: 641px) and (max-width: 910px)",
  });

  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [statement, setStatement] = useState(false);
  const [email, setEmail] = useState(false);

  const [gender, setGender] = useState(null);
  const [
    how_do_you_know_about_this_program,
    set_how_do_you_know_about_this_program,
  ] = useState([
    {
      label: "Social media",
      isSpecify: true,
      checked: false,
      description: "",
    },
    { label: "Friends/relatives", isSpecify: false, checked: false },
    { label: "Website", isSpecify: false, checked: false },
    { label: "Event", isSpecify: false, checked: false },
    { label: "Email", isSpecify: false, checked: false },
    { label: "Other", isSpecify: true, checked: false, description: "" },
  ]);

  const handleStatement = () => setStatement(!statement);
  const handleEmail = () => setEmail(!email);

  const handleOnChangeHowDoYouKnow = (value, propLabel, inputType) => {
    let final_how_do_you_know_about_this_program =
      how_do_you_know_about_this_program;
    final_how_do_you_know_about_this_program =
      final_how_do_you_know_about_this_program.map((obj) => {
        const { label, checked } = obj || {};
        if (propLabel === label) {
          if (inputType === "checkbox") {
            obj.checked = !checked;
          } else {
            obj.description = value;
          }
        }
        return obj;
      });
    set_how_do_you_know_about_this_program(
      final_how_do_you_know_about_this_program
    );
  };

  const handleOnChangeGender = (value) => {
    setGender(value);
  };

  const onSubmit = (values) => {
    if (statement && isCaptchaCorrect) {
      let value_how_do_you_know_about_this_program = "";
      for (let h = 0; h < how_do_you_know_about_this_program.length; h++) {
        const { checked, isSpecify, label, description } =
          how_do_you_know_about_this_program[h];
        if (checked) {
          if (!value_how_do_you_know_about_this_program) {
            if (isSpecify) {
              value_how_do_you_know_about_this_program = `${label} (${description})`;
            } else {
              value_how_do_you_know_about_this_program = `${label}`;
            }
          } else {
            if (isSpecify) {
              value_how_do_you_know_about_this_program += `, ${label} (${description})`;
            } else {
              value_how_do_you_know_about_this_program += `, ${label}`;
            }
          }
        }
      }
      sendNotificationRegistrationESGtoAdmin({
        full_name: values.fullName,
        gender: (gender && gender.value) || "",
        email_address: values.email,
        phone_number: values.phone,
        company_name: values.company,
        job_department_or_position: values.job_department_or_position,
        why_interested_to_join: values.message,
        how_do_you_know_about_this_program:
          value_how_do_you_know_about_this_program,
      })
        .then((res) => {
          openModal();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(`Oops..`, `Something's wrong`, `error`);
        });
    } else {
      Swal.fire(
        `Oops..`,
        `Please verify our captcha and check our privacy statement`,
        `error`
      );
    }
  };

  const onChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="contact">
      <Header />
      <ContainerText
        props={
          <div
            className="contactContainer"
            style={{ marginTop: isMobileSize || isTabSize ? "5vh" : "-30vh" }}
          >
            <div className="contactFormContainer">
              <div className="contactBannerTitle header">
                <p>Registration Form</p>
              </div>
              <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Full Name</label>
                  <input
                    name="fullName"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your name"
                    {...register("fullName", {
                      required: "Please input your full name",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "alphabet characters only",
                      },
                      minLength: {
                        value: 3,
                        message: "mininum 3 characters",
                      },
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="fullName"
                    render={({ message }) => <p className="text">{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Gender</label>
                  <DropdownSelect
                    placeholder="Choose your gender"
                    options={[
                      { value: "Male", label: "Male" },
                      { value: "Female", label: "Female" },
                    ]}
                    onChange={(value) => handleOnChangeGender(value)}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="gender"
                    render={({ message }) => <p className="text">{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Your Email Address</label>
                  <input
                    name="email"
                    type="email"
                    className="form-control text"
                    placeholder="Enter your email address"
                    {...register("email", {
                      required: "Please enter valid email address",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "email format invalid, please try again..",
                      },
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => <p className="text">{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Your Phone Number</label>
                  <input
                    name="phone"
                    type="number"
                    className="form-control text"
                    placeholder="Enter your phone number"
                    {...register("phone", {
                      required: "Please enter your phone number",
                      pattern: {
                        value: /^(?=.*\d).{8,13}$/,
                        message:
                          "Must be minimum 8 characters and maximum 13 characters",
                      },
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="phone"
                    render={({ message }) => <p className="text">{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">
                    Company/Organization Name
                  </label>
                  <input
                    name="company"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your company/organization name"
                    {...register("company", {
                      required: "Please enter your company/organization name",
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="company"
                    render={({ message }) => <p className="text">{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">
                    Job Department/Position
                  </label>
                  <input
                    name="job_department_or_position"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your job department/position"
                    {...register("job_department_or_position", {
                      required: "Please enter your job department/position",
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="job_department_or_position"
                    render={({ message }) => <p className="text">{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">
                    Why are you interested to join Diploma PM for ESG program?
                  </label>
                  <textarea
                    name="message"
                    className="form-control text"
                    rows="8"
                    placeholder="Enter your message"
                    {...register("message", {
                      required: "Please input your message",
                    })}
                    style={{ marginBottom: "0px" }}
                  ></textarea>
                  <ErrorMessage
                    errors={errors}
                    name="message"
                    render={({ message }) => <p className="text">{message}</p>}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">
                    How do you know about this program?
                  </label>
                </div>
                <div
                  className="contactFormCheckbox"
                  style={{ marginTop: "-1vh" }}
                >
                  {how_do_you_know_about_this_program &&
                    Array.isArray(how_do_you_know_about_this_program) &&
                    how_do_you_know_about_this_program.length > 0 &&
                    how_do_you_know_about_this_program.map((obj) => {
                      const { label, checked, isSpecify } = obj || {};
                      return (
                        <>
                          <input
                            onChange={({ target: { value } = {} }) =>
                              handleOnChangeHowDoYouKnow(
                                value,
                                label,
                                "checkbox"
                              )
                            }
                            checked={checked}
                            type="checkbox"
                          />
                          <span className="text">{label}</span>
                          {checked && isSpecify && (
                            <input
                              onChange={({ target: { value } = {} }) =>
                                handleOnChangeHowDoYouKnow(
                                  value,
                                  label,
                                  "input"
                                )
                              }
                              placeholder="Please specify.."
                              checked={checked}
                              type="input"
                              style={{ marginLeft: "2vw" }}
                              className="text"
                            />
                          )}
                          <br />
                        </>
                      );
                    })}
                </div>
                <div className="dipPmESGDescEachContainerTitleHr" />
                <div className="contactFormCheckbox text">
                  <input
                    onChange={handleStatement}
                    checked={statement}
                    type="checkbox"
                  />
                  <span>
                    {" "}
                    I agree to the ISA's{" "}
                    <a
                      href="Policy/PrivacyStatement"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy statement
                    </a>
                  </span>
                  <br />
                  <input
                    onChange={handleEmail}
                    checked={email}
                    type="checkbox"
                  />
                  <span>
                    {" "}
                    I allow ISA to send me email communications, including
                    marketing promotions, insights and news updates
                  </span>
                </div>
                <div style={{ display: "block" }}>
                  <ReCAPTCHA
                    sitekey="6LfXMYAeAAAAAAjkbjSL22SnQ5lqP29nDdPDuAO0"
                    onChange={onChangeCaptcha}
                  />
                </div>
                <div className="contactFormSubmit">
                  <button
                    type="submit"
                    className="btn btn-warning paragrafDesc"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        }
      />
      <div id="popupModal" className="overlay">
        <div className="popup">
          <img src={PopupMessage} alt="popupMessage"></img>
          <p className="headerDescription">Thank you for your registration</p>
          <a className="popupClose" href="#contact" onClick={closeModal}>
            &times;
          </a>
          <div className="popupContent paragraf">
            We have received your registration information, our team will get
            back to you as soon as possible through email.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationForm;
