import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS styling
import "../../styles/activitiesPublic.css";

// Axios and API
import { fetchActivities } from "../../api/fetchActivities";

// React Slideshow Image
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Helpers
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter.js";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import Loader from "../../components/Loader";

const Activities = () => {
  const navigate = useNavigate();

  const [activities, setActivities] = useState([]);
  const [viewAllActivities, setViewAllActivites] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const fetchAllActivities = async (props, hideBtnSeeMore) => {
    try {
      setLoading(true);
      let params = null;
      if (props && props.limit) {
        params = { limit: props.limit };
      }

      const response = await fetchActivities(params);

      if (response && response.status === 200 && response.data) {
        const { data = [] } = response || {};
        setActivities(data);
      }
    } catch (err) {
      console.log(err, "err <<<");
    } finally {
      setLoading(false);
      if (hideBtnSeeMore) {
        setViewAllActivites(true);
      }
    }
  };

  useEffect(() => {
    fetchAllActivities({ limit: 12 });
  }, []);

  const buttonStyle = {
    position: "absolute",
    zIndex: "1",
    cursor: "pointer",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    color: "#000000",
    fontSize: "80px",
    padding: "20px",
    borderRadius: "50%",
  };

  const properties = {
    prevArrow: (
      <button className="arrow arrow-left" style={{ ...buttonStyle, left: 0 }}>
        <span>&lt;</span>
      </button>
    ),
    nextArrow: (
      <button
        className="arrow arrow-right"
        style={{ ...buttonStyle, right: 0 }}
      >
        <span>&gt;</span>
      </button>
    ),
  };

  return (
    <div id="activityPublic">
      <Header />
      <div className="activityPublicListContainer">
        <div className="activityPublicCardListContainer">
          <div className="activityTitle header">
            <p>Activities</p>
          </div>
          <div className="activityPublicListCards">
            {activities &&
              Array.isArray(activities) &&
              activities.length > 0 &&
              activities.map((activity, index) => {
                const {
                  id,
                  title,
                  activityType,
                  link,
                  ActivityImages,
                  ActivityTags,
                  input_summary,
                } = activity || {};
                return (
                  <div className="activityPublicListEachCard" key={index}>
                    <div>
                      {ActivityImages &&
                      Array.isArray(ActivityImages) &&
                      ActivityImages.length > 1 ? (
                        <Slide {...properties}>
                          {ActivityImages.map((slideImage, idx) => {
                            const { img_url } = slideImage;
                            return (
                              <div
                                key={idx}
                                className="activityPublicCardPicture"
                              >
                                <img
                                  key={idx}
                                  src={img_url}
                                  alt={`dummy slide ${idx + 1}`}
                                />
                              </div>
                            );
                          })}
                        </Slide>
                      ) : ActivityImages &&
                        Array.isArray(ActivityImages) &&
                        ActivityImages.length === 1 ? (
                        <div className="activityPublicCardPicture">
                          <img
                            src={ActivityImages[0]["img_url"]}
                            alt={`dummy slide`}
                          ></img>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="activityPublicCardTitle"
                      onClick={() => navigate(`/activities/${id}`)}
                    >
                      <p className="title">{capitalizeFirstLetter(title)}</p>
                    </div>
                    <div
                      className="activityPublicCardType paragraf"
                      style={{ lineHeight: "30px" }}
                    >
                      <p>{capitalizeFirstLetter(activityType)}</p>
                    </div>
                    <div
                      className="activityPublicCardDescription paragrafDesc"
                      style={{ lineHeight: "30px" }}
                    >
                      <p>{input_summary}</p>
                    </div>
                    {ActivityTags &&
                    Array.isArray(ActivityTags) &&
                    ActivityTags.length > 0 ? (
                      <div style={{ marginTop: "20px" }}>
                        <div className="blogCardTags paragraf">Tags:</div>
                        {ActivityTags.map((tag, index) => {
                          const { Tag: { tag_name } = {} } = tag;
                          return (
                            <div
                              className="blogCardTagsBoxContainer"
                              key={index}
                            >
                              <div className="blogCardTagsBox paragrafDesc">
                                <p>{capitalizeFirstLetter(tag_name)}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    {link ? (
                      <div style={{ marginTop: "20px" }}>
                        <div className="blogCardTags paragraf">Link</div>
                        <div className="blogCardTagsBoxContainer paragrafDesc">
                          <a
                            href={`https://${link}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {link}
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
          {!viewAllActivities ? (
            <div
              className="buttonDownloadHereDiplomaESGContainer"
              style={{ marginBottom: "3em" }}
            >
              <button
                className="btn paragraf"
                onClick={() => fetchAllActivities(null, true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Loader
                    type="spin"
                    color="black"
                    height="35px"
                    width="35px"
                  />
                ) : (
                  "See more"
                )}
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Activities;
