import React, { useEffect, useState } from "react";

// Axios
import Axios from "../../api/axios.js";

// React DOM
import { useParams, useNavigate } from "react-router-dom";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// Icons & Backgrounds
import LinkedInBlackLogo from "../../images/icons/LinkedInBlack.png";
import CompanyBanner from "../../images/background/company.png";
import OurHoldingCompany from "../../images/background/ISA_ABOUT.jpg";
import OrganizationStructure from "../../images/background/organizationStructure.jpg";
import ISA_team_members from "../../images/background/ISA_team_members.jpeg";
import OurValuesExcellence from "../../images/icons/ourValues/ISA_excellence.png";
import OurValuesDifference from "../../images/icons/ourValues/ISA_make a difference.png";
import OurValuesHardwork from "../../images/icons/ourValues/ISA_hardwork.png";
import OurValuesIntegrity from "../../images/icons/ourValues/ISA_having integrity.png";
import OurValuesHonesty from "../../images/icons/ourValues/ISA_honesty.png";
import OurValuesDiversity from "../../images/icons/ourValues/ISA_respect diversity.png";

// CSS styling
import "../../styles/about.css";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

// Apis
import { fetchTestimonials } from "../../api/fetchTestimonials.js";

const About = () => {
  const [team, setTeam] = useState([]);
  const { params } = useParams();
  const navigate = useNavigate();
  const [testimionials, setTestimonials] = useState([]);

  const fetchTeamMembers = async () => {
    const response = await Axios.get("/teamMember").catch((err) => {
      console.log("Err: ", err);
    });
    setTeam(response.data);
  };

  const fetchAllTestimonials = async () => {
    const response = await fetchTestimonials("ASC");
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      setTestimonials(data);
    }
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    fetchTeamMembers();
    fetchAllTestimonials();
  }, []);

  return (
    <div id="about">
      <Header />
      {params === "TheFounder" ? (
        <div id="theFounder" style={{ display: "none" }}>
          <div className="founderProfileContainer">
            <div className="founderProfileTitle">
              <p>The Founder</p>
            </div>
            <div className="founderProfileBio">
              <div className="founderProfileBioPicture">
                <img
                  src="https://cdn.discordapp.com/attachments/796711355876245534/938642767654027274/pexels-photo-9065327_1.png"
                  alt="founder"
                />
              </div>
              <div className="founderProfileBioText">
                <div className="founderProfileBioTextQuote">
                  <p>“Lorem Ipsum Dolor Sit Amet”</p>
                </div>
                <div className="founderProfileBioTextName">
                  <p>
                    Dr. Maria Nindita Radyati, Dip. Cons, Dip. PM, Cert. IV. IRM
                  </p>
                </div>
                <div className="founderProfileBioTextTitle">
                  <p>
                    President Director Institute for Sustainability and Agility
                    (ISA)
                  </p>
                </div>
                <div className="founderProfileBioTextLinkedIn">
                  <a href="#" target="_blank" rel="noreferrer">
                    <img src={LinkedInBlackLogo} alt="LinkedInFounder" />
                  </a>
                  <a href="#" target="_blank" rel="noreferrer">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutFounder">
            <div className="aboutFounderMainContainer">
              <div className="founderAbout">
                <div className="aboutUsTitle">
                  <p>About</p>
                  <hr />
                </div>
                <div className="aboutText">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Eget convallis feugiat facilisis morbi amet. Tristique
                    mauris cursus aenean tortor, interdum. Lectus et duis odio
                    felis sagittis lectus at eu. Ac erat elit lorem sit euismod
                    diam. Scelerisque potenti cursus tincidunt sagittis sed
                    scelerisque ut aliquet. Pretium tincidunt felis id elit
                    etiam posuere amet ut nulla. Commodo commodo faucibus luctus
                    sed at gravida amet, lectus convallis.
                  </p>
                  <p>
                    Tincidunt sed tincidunt eget fringilla ante sed pellentesque
                    amet, enim. Et pellentesque gravida ac venenatis eu commodo
                    diam quisque diam. Egestas porta et gravida nullam eget in
                    nunc dictumst. Enim, eu mattis vitae leo nec massa lectus.
                    Ac varius vulputate tortor amet, pretium. Ut maecenas arcu
                    at ac nunc bibendum.
                  </p>
                  <p>
                    Penatibus sed pharetra quis sit sodales. At phasellus cum
                    leo odio pulvinar imperdiet adipiscing est. Fringilla felis
                    enim aliquet ut fermentum velit lacus, felis. Porta dictum
                    justo, mi curabitur ac justo.
                  </p>
                  <p>
                    Dui, a vitae amet nec eget vulputate. Pretium proin
                    tincidunt euismod sapien, senectus at ac sed. Platea neque,
                    ac justo, ultrices sit consequat fames elementum. Semper ac
                    venenatis dolor, in at est id nibh. Amet sed tincidunt
                    pharetra, purus rhoncus non. Sapien netus purus, at velit.
                  </p>
                </div>
              </div>
              <div className="founderPublishedWorks">
                <div className="aboutUsTitle">
                  <p>Published Works</p>
                  <hr />
                </div>
                <div className="publishedWorksList">
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      <a href="">click here to download</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="aboutFounderSpecialisation">
              <div className="specialisationTitle">
                <p>Specialisation</p>
              </div>
              <div className="specialisationList">
                <ul>
                  <li>Corporate Social Responsibility (CSR)</li>
                  <li>Sustainability</li>
                  <li>Environmental, Social and Governance (ESG)</li>
                  <li>Inclusive Finance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {params === "Company" ? (
        <div id="company">
          <div className="companyMainContainer">
            <div className="companyBanner">
              <img src={CompanyBanner} alt="company" />
              <ContainerText
                props={
                  <div className="companyBannerText">
                    <div className="companyBannerTitle header">
                      <p>Our Company</p>
                    </div>
                    <div className="companyBannerSubtitle title">
                      <p>
                        We are Indonesia's leading in sustainability education
                        and consultancy, and we always strive to inspire
                        excellence and make a difference in the world.
                      </p>
                    </div>
                  </div>
                }
              />
            </div>
            <ContainerText
              props={
                <div className="aboutISA">
                  <div className="aboutISAMainContainer">
                    <div className="aboutUsTitleCenter smallScreen">
                      <p>
                        We are Indonesia's leading in sustainability education
                        and consultancy, and we always strive to inspire
                        excellence and make a difference in the world.
                      </p>
                    </div>
                    <div className="aboutUsTitleCenter header">
                      <p>What makes us different</p>
                    </div>
                    <div className="aboutISAsubtext paragraf">
                      <p>
                        Our vision is to do those things{" "}
                        <strong>through collaboration and education.</strong> It
                        is important to share knowledge about sustainability,
                        why it’s important, how to achieve it and what it brings
                        us once we do so. At the same time, it is equally
                        important to collaborate in order to achieve
                        sustainability and leave a lasting impact for the coming
                        generation. In the journey towards our vision, we also
                        want to be recognized{" "}
                        <strong>
                          as a credible think-tank for leadership, education,
                          and partnership in sustainability for the world.
                        </strong>{" "}
                        We seek to encourage the discussion of sustainability
                        within any organization and any group of people. This is
                        because we believe that conversations can lead to ideas,
                        and ideas can lead to the betterment of the world for
                        the generation that will inherit it.
                      </p>
                    </div>
                  </div>
                </div>
              }
            />
            <div className="ISAcompany">
              <ContainerText
                marginRight={0}
                props={
                  <div className="ISAcompanyMainContainer">
                    <div className="ISAcompanyText">
                      <div className="aboutUsTitle header">
                        <p>Our Holding Company</p>
                        <hr />
                      </div>
                      <div className="ISAcompanyTextSub paragraf">
                        <p>
                          <strong>
                            Institute for Sustainability and Agility (ISA) is a
                            Sustainability Consulting company under the legal
                            entity of PT Cendekia Edukasi Citra Tritunggal.
                            <br />
                          </strong>
                          Our holding company,
                          <strong>
                            {" "}
                            PT Cendekia Edukasi Citra Tritunggal{" "}
                          </strong>
                          houses several specializations, which are;
                          <ul>
                            <li>
                              <strong>ISA</strong>: Indonesia’s leading in
                              sustainability education and consultancy. ISA also
                              serves as the imprint of PT Cendekia Edukasi Citra
                              Tritunggal which officially publishes our media
                              (including books or guidebooks).
                            </li>
                            <li>
                              <strong>Combined Clinics Sustainability</strong>:
                              an Education Platform to help you upscale your
                              business knowledge (coming soon)
                            </li>
                            <li>
                              <strong>Cloudxier</strong>: a tech consulting
                              company providing end-to-end technology solutions
                              to help accelerating business in digital
                              innovation for a better and sustainable future
                            </li>
                          </ul>
                        </p>
                      </div>
                      <div className="ISAcompanyButton">
                        <button
                          type="button"
                          className="btn btn-warning paragrafDesc"
                          onClick={() => navigate("/Expertise")}
                        >
                          Learn more
                        </button>
                      </div>
                    </div>
                  </div>
                }
              />
              <div className="ISAcompanyPicture">
                <img src={OurHoldingCompany} alt="ISA" />
              </div>
            </div>
            <div className="valuesCompany">
              <ContainerText
                props={
                  <div className="valuesCompanyContainer">
                    <div className="ISAcompanyPicture">
                      <img src={OrganizationStructure} alt="ISA" />
                    </div>
                    <div className="webTitle header">
                      <p>Our Values</p>
                    </div>
                    <div className="valuesCompanySubtitle title">
                      <p>
                        There are the things that we put value on when we're
                        doing our work:
                      </p>
                    </div>
                    <div className="valuesContent">
                      <div className="valuesEachContent">
                        <img src={OurValuesExcellence} alt="Idea" />
                        <div className="valuesEachContentTitle title">
                          <p>Excellence</p>
                        </div>
                        <div className="valuesEachContentSubtitle paragraf">
                          <p>
                            We strive to give the best and nothing but the best.
                            For us, excellence is a non-negotiable.
                          </p>
                        </div>
                      </div>
                      <div className="valuesEachContent">
                        <img src={OurValuesDifference} alt="Idea" />
                        <div className="valuesEachContentTitle title">
                          <p>Make a Difference</p>
                        </div>
                        <div className="valuesEachContentSubtitle paragraf">
                          <p>
                            We want to leave a lasting impact on our clients and
                            collaborators. We want to innovate from past
                            experiences and make new things.
                          </p>
                        </div>
                      </div>
                      <div className="valuesEachContent">
                        <img src={OurValuesHardwork} alt="Idea" />
                        <div className="valuesEachContentTitle title">
                          <p>Hard Work</p>
                        </div>
                        <div className="valuesEachContentSubtitle paragraf">
                          <p>
                            We don’t stop until we finish whatever is required
                            of us, here at ISA we like to push boundaries.
                          </p>
                        </div>
                      </div>
                      <div className="valuesEachContent">
                        <img src={OurValuesIntegrity} alt="Idea" />
                        <div className="valuesEachContentTitle title">
                          <p>Having Integrity</p>
                        </div>
                        <div className="valuesEachContentSubtitle paragraf">
                          <p>
                            Strong principles are the backbone of thriving
                            organizations and as such, we want to uphold our
                            principles and not compromise on them.
                          </p>
                        </div>
                      </div>
                      <div className="valuesEachContent">
                        <img src={OurValuesHonesty} alt="Idea" />
                        <div className="valuesEachContentTitle title">
                          <p>Honesty</p>
                        </div>
                        <div className="valuesEachContentSubtitle paragraf">
                          <p>
                            Someone once said that honesty is the best policy.
                            Honestly we don’t know who said it, but our policy
                            definitely includes it.
                          </p>
                        </div>
                      </div>
                      <div className="valuesEachContent">
                        <img src={OurValuesDiversity} alt="Idea" />
                        <div className="valuesEachContentTitle title">
                          <p>Respect Diversity</p>
                        </div>
                        <div className="valuesEachContentSubtitle paragraf">
                          <p>
                            A diverse team of people means a diverse range of
                            backgrounds and ideas, and we are not shy to take a
                            shot at those ideas.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      ) : null}
      {params === "TeamMembers" ? (
        <div id="teamMembers">
          <div className="teamMembersMainContainer">
            <div className="teamMembersBanner">
              <img src={ISA_team_members} alt="teamMember" />
              <ContainerText
                props={
                  <div className="teamMembersBannerText">
                    <div className="teamMembersBannerTitle header">
                      <p>Team Members</p>
                    </div>
                  </div>
                }
              />
            </div>
            <div className="ourCurrentTeamMembersTitle header">
              <p>Our Current Team Members</p>
            </div>
            <ContainerText
              props={
                <div className="testimonialMembersContainer">
                  {team.map((team) => (
                    <div
                      className="eachMembers"
                      key={team.id}
                      style={{ maxWidth: "300px", margin: "50px" }}
                    >
                      {team.description !== "" && team.description !== null ? (
                        <div>
                          <div
                            className="eachMembersPicture"
                            onClick={() =>
                              navigate(`/About/TeamMembers/${team.id}`)
                            }
                          >
                            <img src={team.picture} alt={team.name} />
                          </div>
                          <div
                            className="eachMembersName"
                            onClick={() =>
                              navigate(`/About/TeamMembers/${team.id}`)
                            }
                            style={{ fontSize: "22px" }}
                          >
                            <p>{team.name}</p>
                          </div>
                          <div
                            className="eachMembersTitle"
                            style={{ textAlign: "center", fontSize: "18px" }}
                          >
                            <p>{team.jobTitle}</p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="eachMembersPicture">
                            <img src={team.picture} alt={team.name} />
                          </div>
                          <div
                            className="eachMembersName"
                            style={{ fontSize: "22px" }}
                          >
                            <p>{team.name}</p>
                          </div>
                          <div
                            className="eachMembersTitle"
                            style={{ textAlign: "center", fontSize: "18px" }}
                          >
                            <p>{team.jobTitle}</p>
                          </div>
                        </div>
                      )}
                      {team.linkedIn !== "" ? (
                        <div className="eachMembersLinkedIn">
                          <a
                            href={team.linkedIn}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={LinkedInBlackLogo} alt="LinkedInTeam" />
                          </a>
                          <div className="eachMembersLinkedInText">
                            <a
                              href={team.linkedIn}
                              target="_blank"
                              rel="noreferrer"
                            >
                              LinkedIn
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              }
            />
            <div className="ourInternTestimonial header">
              <p>ISA Fellows</p>
            </div>
            <ContainerText
              props={
                <div className="testimonialMembersContainer">
                  {testimionials &&
                    Array.isArray(testimionials) &&
                    testimionials.length > 0 &&
                    testimionials.map((eachTestimonial, index) => {
                      const {
                        participant_name,
                        participant_details,
                        image_url,
                        testimonial,
                      } = eachTestimonial || {};
                      return (
                        <div className="eachMembers" key={index}>
                          <div className="eachMembersDescContainer">
                            <div style={{ maxWidth: "300px" }}>
                              <div className="eachMembersPicture">
                                <img src={image_url} alt={participant_name} />
                              </div>
                              <div
                                className="internName"
                                style={{ marginTop: "1em", fontSize: "22px" }}
                              >
                                <p>{participant_name}</p>
                              </div>
                              <div className="eachMembersTitle">
                                <p style={{ fontSize: "18px" }}>
                                  {participant_details}
                                </p>
                              </div>
                            </div>
                            <div className="containerDesc">
                              <div
                                className="eachMembersDesc"
                                style={{ textAlign: "justify" }}
                              >
                                <p className="paragrafDesc">{testimonial}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              }
            />
          </div>
        </div>
      ) : null}
      <Footer />
    </div>
  );
};

export default About;
