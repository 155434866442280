import { useEffect, useState } from "react";

// Axios
import Axios from "../../api/axios";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// React Form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// Images and Icons
import ChatBubble from "../../images/icons/ChatBubble.png";
import PopupMessage from "../../images/icons/PopupMessage1.png";

// CSS styling
import "../../styles/contact.css";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// Helpers
import { openModal } from "../../helpers/openModal";
import { closeModal } from "../../helpers/closeModal";

// Sweetalert
import Swal from "sweetalert2";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [statement, setStatement] = useState(false);
  const [email, setEmail] = useState(false);

  const handleStatement = () => setStatement(!statement);
  const handleEmail = () => setEmail(!email);

  const onSubmit = (values) => {
    if (statement && isCaptchaCorrect) {
      Axios.post("/contact", {
        fullName: values.fullName,
        email: values.email,
        phone: values.phone,
        company: values.company,
        jobTitle: values.jobTitle,
        message: values.message,
      })
        .then((res) => {
          openModal();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(`Oops..`, `Something's wrong`, `error`);
        });
    } else {
      Swal.fire(
        `Oops..`,
        `Please verify our captcha and check our privacy statement`,
        `error`
      );
    }
  };

  const onChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="contact">
      <Header />
      <ContainerText
        props={
          <div className="contactContainer">
            <div className="contactBannerContainer">
              <div className="contactBannerTitle header">
                <p>Contact Us</p>
              </div>
              <div className="contactBannerSubTitle headerDescription">
                <p>Lets keep in touch</p>
              </div>
              <div className="contactHeadOffice">
                <div className="contactMainTitle title">
                  <p>Head Office</p>
                </div>
                <div className="contactHeadOfficeSubTitle paragrafDesc">
                  <p>
                    at PT. Cendekia Edukasi Citra Tritunggal HQ | AKR Tower 16
                    <sup>th</sup> Floor Unit 16A
                  </p>
                  <p>
                    Jl Panjang No. 5 Kebon Jeruk 11530 Jakarta Barat, Indonesia
                  </p>
                </div>
                <div className="contactHeadOfficeEmail paragrafDesc">
                  <p>
                    <span>Email:</span> company@sustainability.co.id
                  </p>
                  <p>
                    <span>Phone:</span> +62 812-1846-7661
                  </p>
                </div>
              </div>
              <div className="contactWhatsApp">
                <div className="contactMainTitle title">
                  <p>Contact us</p>
                </div>
                <div className="contactWhatsAppSubTitle paragrafDesc">
                  <p>via Whatsapp</p>
                </div>
                <div className="contactWhatsAppButton paragrafDesc">
                  <a
                    href="https://api.whatsapp.com/send/?phone=6281218467661&text&app_absent=0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ color: "black" }}
                      src={ChatBubble}
                      alt="chatBubble"
                    />{" "}
                    Click here
                  </a>
                </div>
              </div>
            </div>
            <div className="contactFormContainer">
              <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Full Name</label>
                  <input
                    name="fullName"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your name"
                    {...register("fullName", {
                      required: "Please input your full name",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "alphabet characters only",
                      },
                      minLength: {
                        value: 3,
                        message: "mininum 3 characters",
                      },
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="fullName"
                    render={({ message }) => (
                      <p className="text">{message}</p>
                    )}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Your Email Address</label>
                  <input
                    name="email"
                    type="email"
                    className="form-control text"
                    placeholder="Enter your email address"
                    {...register("email", {
                      required: "Please enter valid email address",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "email format invalid, please try again..",
                      },
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <p className="text">{message}</p>
                    )}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Your Phone Number</label>
                  <input
                    name="phone"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your phone number"
                    {...register("phone", {
                      required: "Please enter your phone number",
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="phone"
                    render={({ message }) => (
                      <p className="text">{message}</p>
                    )}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Company/Organization Name</label>
                  <input
                    name="company"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your company/organization name"
                    {...register("company", {
                      required: "Please enter your company/organization name",
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="company"
                    render={({ message }) => (
                      <p className="text">{message}</p>
                    )}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Job Title/Position</label>
                  <input
                    name="jobTitle"
                    type="text"
                    className="form-control text"
                    placeholder="Enter your job title/position"
                    {...register("jobTitle", {
                      required: "Please enter your job title/position",
                    })}
                    style={{ marginBottom: "0px" }}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="jobTitle"
                    render={({ message }) => (
                      <p className="text">{message}</p>
                    )}
                  />
                </div>
                <div className="contactFormSection">
                  <label className="paragrafDesc">Your message</label>
                  <textarea
                    name="message"
                    className="form-control text"
                    rows="8"
                    placeholder="Enter your message"
                    {...register("message", {
                      required: "Please input your message",
                    })}
                    style={{ marginBottom: "0px" }}
                  ></textarea>
                  <ErrorMessage
                    errors={errors}
                    name="message"
                    render={({ message }) => (
                      <p className="text">{message}</p>
                    )}
                  />
                </div>
                <div className="contactFormCheckbox text">
                  <input
                    onChange={handleStatement}
                    checked={statement}
                    type="checkbox"
                  />
                  <span className="text">
                    {" "}
                    I agree to the ISA's{" "}
                    <a
                      href="Policy/PrivacyStatement"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy statement
                    </a>
                  </span>
                  <br />
                  <input
                    onChange={handleEmail}
                    checked={email}
                    type="checkbox"
                  />
                  <span>
                    {" "}
                    I allow ISA to send me email communications, including
                    marketing promotions, insights and news updates
                  </span>
                </div>
                <div className="contactFormSubmit">
                  <ReCAPTCHA
                    sitekey="6LfXMYAeAAAAAAjkbjSL22SnQ5lqP29nDdPDuAO0"
                    onChange={onChangeCaptcha}
                  />
                  <button type="submit" className="btn btn-warning paragrafDesc">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        }
      />
      <div id="popupModal" className="overlay">
        <div className="popup">
          <img src={PopupMessage} alt="popupMessage"></img>
          <p>Thank you for your inquiry</p>
          <a className="popupClose" href="#contact" onClick={closeModal}>
            &times;
          </a>
          <div className="popupContent">
            We have received your inquiry, our team will get back to you as soon
            as possible through email.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
