// Axios
import Axios from "./axios";

export const fetchActivities = (prop) => {
  return new Promise((resolve, reject) => {
    let data;
    if (prop) {
      data = Axios.get(`/activities`, { params: { limit: prop.limit } });
    } else {
      data = Axios.get(`/activities`);
    }
    resolve(data);
  });
};
