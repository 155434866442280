import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// CSS styling
import "../../styles/activitiesPublic.css";
import "../../styles/clientBook.css";

// HTML react parser
import parse from "html-react-parser";

// react responsive
import { useMediaQuery } from "react-responsive";

// moment js
import moment from "moment";

// helpers
import { getAPIUrl } from "../../helpers/common.js";

// apis
import { fetchBookDetails } from "../../api/books.js";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import ContainerText from "../../components/Container.js";

const BookDetails = () => {
  const { book_id } = useParams();

  const isMobileSize = useMediaQuery({ query: "(max-width: 680px)" });
  const isTabSize = useMediaQuery({
    query: "(min-width: 681px) and (max-width: 910px)",
  });

  const [bookDetails, setBookDetails] = useState(null);

  const handleFetchBookDetails = async (book_id) => {
    const result = await fetchBookDetails(book_id);
    if (result && result.data) {
      setBookDetails(result.data);
    }
  };

  const handleDownloadEbook = (book_id) => {
    window.open(`${getAPIUrl()}/download/books/${book_id}`, "_blank");
  };

  useEffect(() => {
    if (book_id) {
      handleFetchBookDetails(book_id);
    }
  }, [book_id]);

  return (
    <div>
      <Header />
      <div className="activityPublicContainer">
        {bookDetails ? (
          <>
            <div className="bookDetailTitleContainer title">
              <div>{bookDetails && bookDetails.title}</div>
            </div>
            <ContainerText
              props={
                <div className="bookDetailsMainContainerDesc">
                  {isMobileSize ? (
                    <>
                      <div className="bookDetailsMainImageMainContainer">
                        <div className="detailBookImageContainer">
                          <img
                            src={bookDetails && bookDetails.img_url}
                            alt={"book image"}
                          />
                        </div>
                        <div
                          className="eachClientBookPublishedDateStyle paragrafDesc"
                          style={{
                            fontWeight: 700,
                            textAlign: "left",
                            paddingTop: "1em",
                          }}
                        >
                          {`Published on: ${
                            bookDetails.published_on
                              ? moment(bookDetails.published_on).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"
                          }`}
                        </div>
                        <div
                          className="eachClientBookPublishedDateStyle paragrafDesc"
                          style={{
                            fontWeight: 700,
                            textAlign: "left",
                          }}
                        >
                          {`Number of Pages: ${bookDetails.number_of_pages}`}
                        </div>
                        {bookDetails.book_authors &&
                        Array.isArray(bookDetails.book_authors) &&
                        bookDetails.book_authors.length > 0 ? (
                          <div>
                            <div
                              className="eachClientBookPublishedDateStyle paragrafDesc"
                              style={{
                                fontWeight: 700,
                                textAlign: "left",
                              }}
                            >
                              {`Author${
                                bookDetails.book_authors.length > 1 ? "(s)" : ""
                              }:`}
                            </div>
                            <ul style={{ textAlign: "left" }}>
                              {bookDetails.book_authors.map((book_author) => {
                                const { id, author_name } = book_author || {};
                                return (
                                  <li key={id}>
                                    <div className="text">{author_name}</div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}
                        <div
                          className="eachClientBookPublishedDateStyle paragrafDesc"
                          style={{
                            fontWeight: 700,
                            textAlign: "left",
                            marginTop: "-12.5px",
                          }}
                        >
                          {`ISBN: ${bookDetails.isbn ? bookDetails.isbn : "-"}`}
                        </div>
                        <div
                          className="downloadBookContainer"
                          onClick={() =>
                            handleDownloadEbook(
                              bookDetails && bookDetails.title
                            )
                          }
                        >
                          <div>Download E-Book</div>
                        </div>
                      </div>
                      <div className="bookDetailsWhatWillYouLearnContainer">
                        <div
                          className="title"
                          style={{
                            color: "#1F628E",
                            fontWeight: "600",
                          }}
                        >
                          What will you learn from this book:
                        </div>
                        {bookDetails && bookDetails.what_you_will_learn ? (
                          <div className="bookDetailsWhatYouWillLearnStyle paragraf">
                            {parse(bookDetails.what_you_will_learn)}
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bookDetailsWhatWillYouLearnContainer">
                        <div
                          className="title"
                          style={{
                            color: "#1F628E",
                            fontWeight: "600",
                          }}
                        >
                          What will you learn from this book:
                        </div>
                        {bookDetails && bookDetails.what_you_will_learn ? (
                          <div className="bookDetailsWhatYouWillLearnStyle paragraf">
                            {parse(bookDetails.what_you_will_learn)}
                          </div>
                        ) : null}
                      </div>
                      <div className="bookDetailsMainImageMainContainer">
                        <div className="detailBookImageContainer">
                          <img
                            src={bookDetails && bookDetails.img_url}
                            alt={"book image"}
                          />
                        </div>
                        <div
                          className="eachClientBookPublishedDateStyle paragrafDesc"
                          style={{
                            fontWeight: 700,
                            textAlign: "left",
                            paddingTop: "1em",
                          }}
                        >
                          {`Published on: ${
                            bookDetails.published_on
                              ? moment(bookDetails.published_on).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"
                          }`}
                        </div>
                        <div
                          className="eachClientBookPublishedDateStyle paragrafDesc"
                          style={{
                            fontWeight: 700,
                            textAlign: "left",
                          }}
                        >
                          {`Number of Pages: ${bookDetails.number_of_pages}`}
                        </div>
                        {bookDetails.book_authors &&
                        Array.isArray(bookDetails.book_authors) &&
                        bookDetails.book_authors.length > 0 ? (
                          <div>
                            <div
                              className="eachClientBookPublishedDateStyle paragrafDesc"
                              style={{
                                fontWeight: 700,
                                textAlign: "left",
                              }}
                            >
                              {`Author${
                                bookDetails.book_authors.length > 1 ? "(s)" : ""
                              }:`}
                            </div>
                            <ul
                              style={{
                                textAlign: "left",
                                fontSize: "14px",
                              }}
                            >
                              {bookDetails.book_authors.map((book_author) => {
                                const { id, author_name } = book_author || {};
                                return (
                                  <li key={id}>
                                    <div className="text">{author_name}</div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}
                        <div
                          className="eachClientBookPublishedDateStyle paragrafDesc"
                          style={{
                            fontWeight: 700,
                            textAlign: "left",
                            marginTop: "-12.5px",
                          }}
                        >
                          {`ISBN: ${bookDetails.isbn ? bookDetails.isbn : "-"}`}
                        </div>
                        <div
                          className="downloadBookContainer"
                          onClick={() =>
                            handleDownloadEbook(
                              bookDetails && bookDetails.title
                            )
                          }
                        >
                          <div>Download E-Book</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              }
            />
          </>
        ) : null}
      </div>
      <Footer />
    </div>
  );
};

export default BookDetails;
